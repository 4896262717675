/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useQuery } from "@tanstack/react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useMemo } from "react";
import { getCompanies } from "services/api";
import Actions from "./actions";
import FlexBox from "components/UI/FlexBox";
import MDButton from "components/MDButton";
import { useGlobalStore } from "store";
import { useModal } from "hooks/use-modal";

function Companies() {
  const { data, isLoading } = useQuery({ queryKey: ["companies"], queryFn: getCompanies });
  const { sidenavColor } = useGlobalStore();
  const { onOpen } = useModal();

  const dataTable = useMemo(() => {
    const table = {
      columns: [
        { Header: "name", accessor: "name" },
        {
          Header: "address",
          accessor: "address",
          Cell: (data) => (
            <MDTypography sx={{ width: "300px", fontSize: 14, color: "inherit" }}>
              {data?.row?.original?.address?.slice(0, 140)}
              {data?.row?.original?.address?.length > 140 ? "..." : ""}
            </MDTypography>
          ),
        },
        { Header: "phone", accessor: "phone" },
        { Header: "email", accessor: "email" },
        {
          Header: "description",
          accessor: "description",
          Cell: (data) => (
            <MDTypography sx={{ width: "300px", fontSize: 14, color: "inherit" }}>
              {data?.row?.original?.description?.slice(0, 140)}
              {data?.row?.original?.description?.length > 140 ? "..." : ""}
            </MDTypography>
          ),
        },
        {
          Header: "actions",
          Cell: (data) => {
            return <Actions rowData={data.row.original} />;
          },
        },
      ],
      rows: [],
    };

    if (!data?.response?.data) return table;
    table.rows = data?.response?.data;
    return table;
  }, [data?.response?.data]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Companies
              </MDTypography>
              <MDTypography variant="button" color="text">
                All Companies Data
              </MDTypography>
            </MDBox>
            <FlexBox px={3} justifyContent="end">
              <MDButton
                variant="contained"
                color={sidenavColor}
                onClick={() => onOpen("addCompany")}
              >
                Add Company
              </MDButton>
            </FlexBox>
            <DataTable table={dataTable} isLoading={isLoading} canSearch />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default Companies;
