/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme-dark/base/colors";

const { transparent, white, error } = colors;

const textField = {
  styleOverrides: {
    root: {
      color: "#fff",
      backgroundColor: transparent.main,
      "& .MuiFormLabel-root": {
        paddingBottom: 1.7,
      },
      "& .MuiFormHelperText-root": {
        color: `${error.main} !important`,
      },
    },
  },
};

export default textField;
