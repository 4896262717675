import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userData } from "./formData";
import { Autocomplete, Stack } from "@mui/material";
import { updateUser } from "services/api";

const notRenderKeys = ["id", "password", "updated_at", "created_at", "created_by", "updated_by"];

function UpdateUserModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "updateUser";
  const queryClient = useQueryClient();
  const [comapny, setComapny] = useState({});

  useEffect(() => {
    setComapny(data);
  }, [data]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateUser,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("user was updated successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(comapny)) {
      if (!val && key !== "password") {
        toast.error(`${key} is required`);
        return;
      }
    }
    mutate({ id: comapny.id, payload: comapny });
  };

  const handleSelectOption = (_, newVal) => {
    setComapny({ ...comapny, role: newVal });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setComapny({ ...comapny, [name]: value });
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Edit User">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(comapny)?.map(([key, value]) =>
            userData[key]?.type === "select" ? (
              <Autocomplete
                onChange={handleSelectOption}
                key={key}
                value={value}
                options={userData[key]?.options}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={userData[key]?.label}
                  />
                )}
              />
            ) : !notRenderKeys.includes(key) ? (
              <MDInput
                key={key}
                value={value}
                variant="standard"
                type={userData[key]?.type}
                label={userData[key]?.label}
                fullWidth
                name={key}
                disabled={key === "username"}
                onChange={handleChangeInput}
              />
            ) : null
          )}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

UpdateUserModal.propTypes;

export default UpdateUserModal;
