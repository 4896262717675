import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import { addUser } from "services/api";
import { userData, addUserPayload } from "./formData";
import { Autocomplete, Stack } from "@mui/material";

function AddUserModal() {
  const { onClose, type } = useModal();
  const isOpenModal = type === "addUser";
  const queryClient = useQueryClient();
  const [user, setUser] = useState(addUserPayload);

  const { mutate, isPending } = useMutation({
    mutationFn: addUser,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("new user was added successfully");
      setUser(addUserPayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(user)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    mutate(user);
  };

  const handleSelectOption = (_, newVal) => {
    setUser({ ...user, role: newVal });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Add User">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(user).map(([key, value]) =>
            userData[key].type === "select" ? (
              <Autocomplete
                onChange={handleSelectOption}
                key={key}
                value={value}
                options={userData[key].options}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={userData[key].label}
                  />
                )}
              />
            ) : (
              <MDInput
                key={key}
                value={value}
                variant="standard"
                type={userData[key].type}
                label={userData[key].label}
                fullWidth
                name={key}
                onChange={handleChangeInput}
              />
            )
          )}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

AddUserModal.propTypes;

export default AddUserModal;
