export const userData = {
  username: {
    label: "Username",
    type: "text",
  },
  password: {
    label: "Password",
    type: "password",
  },
  email: {
    label: "Email",
    type: "email",
  },
  role: {
    label: "Role",
    type: "select",
    options: ["admin", "user", "warehouse"],
  },
};

export const addUserPayload = {
  username: "",
  password: "",
  email: "",
  role: "",
};

export const companyData = {
  name: {
    label: "Name",
    type: "text",
  },
  address: {
    label: "Address",
    type: "text",
  },
  email: {
    label: "Email",
    type: "email",
  },
  phone: {
    label: "Phone",
    type: "number",
  },
  description: {
    label: "Description",
    type: "text",
  },
};

export const addCompanyPayload = {
  name: "",
  address: "",
  email: "",
  phone: "",
  description: "",
};

export const productData = {
  name: {
    label: "Product Name",
    type: "text",
  },

  hs_code: {
    label: "HS Code",
    type: "number",
  },
  quantity: {
    label: "Quantity",
    type: "number",
  },
  quantity_name: {
    label: "Quantity Name",
    type: "text",
  },
  gross_weight: {
    label: "Gross Weight",
    type: "number",
  },
  nett_weight: {
    label: "Nett Weight",
    type: "number",
  },
  commercial_invoice_id: {
    label: "Commercial Invoice ID",
    type: "number",
  },
  image: {
    label: "Image URL",
    type: "url",
  },
  unit_price: {
    label: "Unit Price",
    type: "text",
  },
  unit_price_name: {
    label: "Unit Price Name",
    type: "text",
  },
  auto_calculated: {
    label: "Auto Calculated",
    type: "checkbox",
  },
  total_price: {
    label: "Total Price",
    type: "text",
  },
  total_price_name: {
    label: "Total Price Name",
    type: "text",
  },
};

export const addProductPayload = {
  name: "",
  hs_code: "",
  quantity: "",
  quantity_name: "",
  gross_weight: "",
  nett_weight: "",
  commercial_invoice_id: "",
  image: "",
  unit_price: "",
  unit_price_name: "",
  auto_calculated: true,
  total_price: "",
  total_price_name: "",
};

export const packageData = {
  name: {
    label: "Name",
    type: "text",
  },
  quantity: {
    label: "Quantity",
    type: "number",
  },
  description: {
    label: "Description",
    type: "text",
  },
  commercial_invoice_id: {
    label: "Invoice Number",
    type: "text",
  },
};

export const addPackagePayload = {
  quantity: "",
  name: "",
  description: "",
  commercial_invoice_id: "",
};

export const packingListData = {
  packing_list_no: {
    label: "Packing List Number",
    type: "text",
  },
  total_packages: {
    label: "Total Packages",
    type: "number",
  },
  total_packages_name: {
    label: "Total Packages Name",
    type: "text",
  },
  commercial_invoice_id: {
    label: "Commercial Invoice ID",
    type: "text",
  },
};

export const addPackingListPayload = {
  packing_list_no: "",
  commercial_invoice_id: "",
};
