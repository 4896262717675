import MDBox from "components/MDBox";

const TabPanel = ({ children, value, index, ...restProps }) => {
  return (
    <MDBox
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...restProps}
    >
      {value === index && <MDBox sx={{ py: 2 }}>{children}</MDBox>}
    </MDBox>
  );
};

TabPanel.propTypes;

export default TabPanel;
