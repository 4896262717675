import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useModal } from "hooks/use-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import { companyData, addCompanyPayload } from "./formData";
import { Stack } from "@mui/material";
import { addCompany } from "services/api";

function AddCompanyModal() {
  const { onClose, type } = useModal();
  const isOpenModal = type === "addCompany";
  const queryClient = useQueryClient();
  const [newCompany, setNewCompany] = useState(addCompanyPayload);

  const { mutate, isPending } = useMutation({
    mutationFn: addCompany,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      toast.success("new company was added successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(newCompany)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    mutate(newCompany);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setNewCompany({ ...newCompany, [name]: value });
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Add Company">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(newCompany).map(([key, value]) => (
            <MDInput
              key={key}
              value={value}
              variant="standard"
              multiline={key === "description"}
              rows={3}
              type={companyData[key]?.type}
              label={companyData[key]?.label}
              fullWidth
              name={key}
              onChange={handleChangeInput}
            />
          ))}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

AddCompanyModal.propTypes;

export default AddCompanyModal;
