/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";

import brand from "assets/images/logo-ala.png";

// react-router-dom components
import { Outlet, useLocation } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { useGlobalStore } from "store";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import routes from "routes";
import { Icon } from "@mui/material";
import { filteredMenuAccess } from "utils";
import { warehouseMenu } from "utils";

function DashboardLayout() {
  const {
    miniSidenav,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    setMiniSidenav,
    setLayout,
    setOpenConfigurator,
    openConfigurator,
    userLoggedIn,
  } = useGlobalStore();

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setLayout("dashboard");
  }, [pathname]);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(!openConfigurator);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(true);
      setOnMouseEnter(false);
    }
  };

  const renderRoutes = useMemo(
    () => filteredMenuAccess(routes, userLoggedIn?.authUser),
    [userLoggedIn?.authUser]
  );

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={(transparentSidenav && !darkMode) || whiteSidenav ? brand : brand}
        brandName="ALA Dashboard"
        routes={renderRoutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      {configsButton}
      <Configurator />
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar />
        <Outlet />
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes;

export default DashboardLayout;
