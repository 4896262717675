import { persist, createJSONStorage } from "zustand/middleware";

const initialState = {
  openModal: null,
  userLoggedIn: null,
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  direction: "ltr",
  layout: "dashboard",
  darkMode: true,
  selectedProduct: {},
  selectedInvoice: {},
};

export const presistStateSlice = persist(
  (set, get) => ({
    ...initialState,
    setUserLoggedIn: (payload) => set({ userLoggedIn: payload }),
    setOpenConfigurator: (payload) => set({ openConfigurator: payload }),
    setSidenavColor: (payload) => set({ sidenavColor: payload }),
    setWhiteSidenav: (payload) => set({ whiteSidenav: payload }),
    setDirection: (payload) => set({ direction: payload }),
    setLayout: (payload) => set({ layout: payload }),
    setFixedNavbar: (payload) => set({ fixedNavbar: payload }),
    setTransparentNavbar: (payload) => set({ transparentNavbar: payload }),
    setMiniSidenav: (payload) => set({ miniSidenav: payload }),
    setDarkMode: (payload) => set({ darkMode: payload }),
    setTransparentSidenav: (payload) => set({ transparentSidenav: payload }),
    setSelectedInvoice: (payload) => set({ selectedInvoice: payload }),
    setOpenModal: (payload) => set({ openModal: payload }),
  }),
  {
    name: "persist-state",
    storage: createJSONStorage(() => sessionStorage),
  }
);
