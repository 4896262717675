import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ThemedIconButton from "components/UI/ThemedIconButton";
import { useGlobalStore } from "store";
import { DeleteForever, MoreVert, Visibility, Edit } from "@mui/icons-material";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCompany } from "services/api";
import DialogConfirm from "examples/Dialogs/DialogConfirm";
import { toast } from "react-toastify";
import { useModal } from "hooks/use-modal";
import { deleteCommercialInvoice } from "services/api";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

function CommercialInvoiceActions({ rowData }) {
  const { darkMode, setSelectedInvoice } = useGlobalStore();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);
  const [dialogConfirm, setDialogConfrim] = useState({ open: false });

  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: deleteCommercialInvoice,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["commercialInvoices"] });
      toast.success("invoice was deleted successfully");
      setDialogConfrim({ ...dialogConfirm, open: false });
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  return (
    <>
      <DialogConfirm {...dialogConfirm} isLoading={isPending} />
      <ThemedIconButton onClick={handleOpenMenu}>
        <MoreVert fontSize="small" />
      </ThemedIconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setSelectedInvoice(rowData);
            navigate(`/logistics/import/commercial-invoices/${rowData.id}`);
          }}
          disableRipple
        >
          <Visibility sx={{ color: darkMode ? "white !important" : "inherit" }} />
          Detail
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedInvoice(rowData);
            navigate(`/logistics/import/commercial-invoices/edit/${rowData.id}`);
          }}
          disableRipple
        >
          <Edit sx={{ color: darkMode ? "white !important" : "inherit" }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogConfrim({
              open: true,
              title: "delete",
              type: "invoice",
              value: `(${rowData?.invoice_no})`,
              note: "Deleting an invoice will also delete the products and packing list related to that invoice.",
              handleClose: () => setDialogConfrim({ ...dialogConfirm, open: false }),
              action: () => mutate(rowData?.id),
            });
            handleCloseMenu();
          }}
          disableRipple
        >
          <DeleteForever sx={{ color: darkMode ? "white !important" : "inherit" }} />
          Delete
        </MenuItem>
      </StyledMenu>
    </>
  );
}

CommercialInvoiceActions.propTypes;
export default CommercialInvoiceActions;
