import { Autocomplete, Card, Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import FlexBox from "components/UI/FlexBox";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalStore } from "store";
import { addInvoicePayload, invoiceData } from "./formData";
import MDCustomDatePicker from "components/MDCustomDatePicker";
import MDInputOrAutocomplete from "components/MDInputOrAutocomplete";
import MDSwitchWithLabel from "components/MDSwitchWithLabel";
import { getCompanies } from "services/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPaymentTerms } from "services/api";
import { addCommercialInvoice } from "services/api";
import { toast } from "react-toastify";

const ManualInvoice = () => {
  const navigate = useNavigate();
  const [autoModeInput, setAutoModeInput] = useState({
    issuer_name: "autocomplete",
    receiver_name: "autocomplete",
    party_name: "autocomplete",
  });
  const [invoice, setInvoice] = useState(addInvoicePayload);
  const { sidenavColor } = useGlobalStore();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setInvoice({ ...invoice, [name]: value });
  };

  const handleChangeAutoComplete = (e, newVal, key) => {
    if (key.includes("issuer")) {
      setInvoice({
        ...invoice,
        issuer_name: newVal,
        issuer_email: newVal?.email,
        issuer_address: newVal?.address,
        issuer_phone: newVal?.phone,
      });
      return;
    }
    if (key.includes("receiver")) {
      setInvoice({
        ...invoice,
        receiver_name: newVal,
        receiver_email: newVal?.email,
        receiver_address: newVal?.address,
        receiver_phone: newVal?.phone,
      });
      return;
    }

    if (key.includes("party")) {
      setInvoice({
        ...invoice,
        party_name: newVal,
        party_email: newVal?.email,
        party_address: newVal?.address,
        party_phone: newVal?.phone,
      });
      return;
    }
    setInvoice({ ...invoice, [key]: newVal });
  };

  const handleToggleMode = (e, key) => {
    const currentInputMode = autoModeInput[key];
    setAutoModeInput({
      ...autoModeInput,
      [key]: currentInputMode === "autocomplete" ? "input" : "autocomplete",
    });
  };

  const { data: companies, isLoading: loadCompanies } = useQuery({
    queryKey: ["companies"],
    queryFn: getCompanies,
  });
  const { data: payments, isLoading: loadPayments } = useQuery({
    queryKey: ["payments"],
    queryFn: getPaymentTerms,
  });

  const companyOptions = useMemo(() => companies?.response?.data || [], [companies]);
  const paymentOptions = useMemo(() => payments?.response?.data || [], [payments]);

  const { mutate, isPending } = useMutation({
    mutationFn: addCommercialInvoice,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      toast.success("new invoice was added successfully");
      setInvoice(addInvoicePayload);
      navigate(
        res?.response?.data?.commercial_invoice_id
          ? `/logistics/import/commercial-invoices/${res?.response?.data?.commercial_invoice_id}`
          : "/logistics/import/commercial-invoices"
      );
    },
  });

  const handleSubmit = () => {
    const payload = {};

    for (const [key, value] of Object.entries(invoice)) {
      if (key.includes("party") && !invoice.notify_party) {
        continue;
      }

      if (key.includes("name")) {
        if (
          (autoModeInput[key] === "autocomplete" && !value?.name) ||
          (autoModeInput[key] !== "autocomplete" && !value)
        )
          return toast.error(`${key} is required`);
        payload[key] = autoModeInput[key] === "autocomplete" ? value?.name : value;
        continue;
      }
      if (key.includes("payment")) {
        if (!value?.id) return toast.error(`${key} is required`);
        payload[key] = value?.id;
        continue;
      }
      if (typeof value === "string" && !value) {
        if (!value?.id) return toast.error(`${key} is required`);
      }

      payload[key] = value;
    }
    mutate(payload);
  };

  return (
    <Card>
      <MDBox py={3} px={5} lineHeight={1}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item lg={6} xs={12}>
            <Stack py={2} component="form" fullWidth spacing={2}>
              {Object.entries(invoice).map(([key, value]) =>
                invoiceData[key]?.type === "autocomplete" ? (
                  <Autocomplete
                    key={key}
                    disableClearable
                    value={value}
                    options={paymentOptions}
                    disabled={loadPayments}
                    getOptionLabel={(option) => option?.name || ""}
                    onChange={(e, newVal) => handleChangeAutoComplete(e, newVal, key)}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label={invoiceData[key]?.label} />
                    )}
                  />
                ) : invoiceData[key]?.type === "date" ? (
                  <MDCustomDatePicker
                    key={key}
                    value={value}
                    label={invoiceData[key]?.label}
                    onChange={(val) => setInvoice({ ...invoice, [key]: val })}
                  />
                ) : invoiceData[key]?.type === "checkbox" ? (
                  <MDBox key={key}>
                    <FormControlLabel
                      sx={{ mr: -1 }}
                      label={invoiceData[key]?.label}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={() => {
                            setInvoice({ ...invoice, [key]: !value });
                          }}
                        />
                      }
                    />
                  </MDBox>
                ) : (invoiceData[key]?.isOption && !key.includes("party")) ||
                  (invoiceData[key]?.isOption &&
                    key.includes("party") &&
                    invoice["notify_party"]) ? (
                  <MDBox key={key}>
                    <MDSwitchWithLabel
                      checked={autoModeInput[key] === "autocomplete"}
                      label="Auto Mode"
                      onChange={(e) => handleToggleMode(e, key)}
                      containerProps={{
                        sx: {
                          mb: "-18px !important",
                        },
                      }}
                    />
                    <MDInputOrAutocomplete
                      key={key}
                      mode={autoModeInput[key]}
                      inputProps={{
                        value: typeof value === "string" ? value : value?.name || "",
                        onChange: handleChangeInput,
                        name: key,
                        type: invoiceData[key]?.type,
                        label: invoiceData[key]?.label,
                      }}
                      autoCompleteProps={{
                        disableClearable: true,
                        value: value,
                        options: companyOptions,
                        label: invoiceData[key]?.label,
                        disabled: loadCompanies,
                        getOptionLabel: (option) => option?.name || "",
                        onChange: (e, newVal) => handleChangeAutoComplete(e, newVal, key),
                      }}
                    />
                  </MDBox>
                ) : (!invoiceData[key]?.isOption && !key.includes("party")) ||
                  (!invoiceData[key]?.isOption &&
                    key.includes("party") &&
                    invoice["notify_party"]) ? (
                  <MDInput
                    value={value}
                    variant="standard"
                    name={key}
                    label={invoiceData[key]?.label}
                    type={invoiceData[key]?.type}
                    disabled={
                      invoiceData[key]?.parent
                        ? autoModeInput[invoiceData[key]?.parent] === "autocomplete"
                        : false
                    }
                    onChange={handleChangeInput}
                  />
                ) : null
              )}
            </Stack>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Stack spacing={2}>
              <MDTypography sx={{ fontWeight: "bold" }}>Invoice Data Preview</MDTypography>
              <Stack spacing={1}>
                {Object.entries(invoice).map(([key, value]) => (
                  <Grid key={key} container>
                    <Grid item lg={3}>
                      <MDTypography sx={{ fontSize: 14 }}>{invoiceData[key]?.label}</MDTypography>
                    </Grid>
                    <Grid item lg={1}>
                      <MDTypography sx={{ fontSize: 14 }}>:</MDTypography>
                    </Grid>
                    <Grid item lg={8}>
                      <MDTypography
                        sx={{
                          fontSize: 14,
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {key.includes("name") || key.includes("payment")
                          ? value?.name
                          : key === "date" && value
                          ? value?.toDateString()
                          : value?.toString()}
                      </MDTypography>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <FlexBox mt={2}>
          <MDButton variant="contained" color={sidenavColor} onClick={handleSubmit}>
            Submit
          </MDButton>
        </FlexBox>
      </MDBox>
    </Card>
  );
};

export default ManualInvoice;
