import { callApi } from "../config";

// Authentication Endpoints
const signIn = (payload) => callApi({ url: "/auth/sign-in", method: "POST", payload });
const refreshToken = (payload) => callApi({ url: "/auth/refresh-token", method: "POST", payload });

// Companies Endpoints
const getCompanies = () => callApi({ url: "/companies", method: "GET" });
const getCompanyById = (id) => callApi({ url: `/companies/${id}`, method: "GET" });
const addCompany = (payload) => callApi({ url: `/companies`, method: "POST", payload });
const updateCompany = ({ id, payload }) =>
  callApi({ url: `/companies/${id}`, method: "PUT", payload });
const deleteCompany = (id) => callApi({ url: `/companies/${id}`, method: "DELETE" });

// Commercial Invoices Endpoints
const getCommercialInvoices = () => callApi({ url: "/commercial-invoices", method: "GET" });
const getCommercialInvoiceById = (id) =>
  callApi({ url: `/commercial-invoices/${id}`, method: "GET" });
const addCommercialInvoice = (payload) =>
  callApi({ url: `/commercial-invoices`, method: "POST", payload });
const updateCommercialInvoice = ({ id, payload }) =>
  callApi({ url: `/commercial-invoices/${id}`, method: "PUT", payload });
const uploadCommercialInvoice = (payload) =>
  callApi({
    url: `/commercial-invoices-file/csv/upload`,
    method: "POST",
    type: "formData",
    payload,
  });
const deleteCommercialInvoice = (id) =>
  callApi({ url: `/commercial-invoices/${id}`, method: "DELETE" });
const updateCommercialInvoiceStatus = ({ id, payload }) =>
  callApi({ url: `/commercial-invoices/${id}/status`, method: "PUT", payload });
const getHistoricalInvoiceStatus = (id) =>
  callApi({ url: `/commercial-invoices/${id}./status`, method: "GET" });

// Users Endpoints
const getUsers = () => callApi({ url: "/users", method: "GET" });
const getUserById = (id) => callApi({ url: `/users/${id}`, method: "GET" });
const addUser = (payload) => callApi({ url: `/users`, method: "POST", payload });
const updateUser = ({ id, payload }) => callApi({ url: `/users/${id}`, method: "PUT", payload });
const deleteUser = (id) => callApi({ url: `/users/${id}`, method: "DELETE" });

// Products Endpoints
const getProducts = () => callApi({ url: "/products", method: "GET" });
const getProductById = (id) => callApi({ url: `/products/${id}`, method: "POST" });
const addProduct = (payload) => callApi({ url: `/products`, method: "POST", payload });
const updateProduct = ({ id, payload }) =>
  callApi({ url: `/products/${id}`, method: "PUT", payload });
const deleteProduct = (id) => callApi({ url: `/products/${id}`, method: "DELETE" });

// Payment Terms Endpoints
const getPaymentTerms = () => callApi({ url: "/payment-terms", method: "GET" });
const getPaymentTermById = (id) => callApi({ url: `/payment-terms/${id}`, method: "GET" });
const addPaymentTerm = (payload) => callApi({ url: `/payment-terms`, method: "POST", payload });
const updatePaymentTerm = (id, payload) =>
  callApi({ url: `/payment-terms/${id}`, method: "PUT", payload });
const deletePaymentTerm = (id) => callApi({ url: `/payment-terms/${id}`, method: "DELETE" });

// Packages Term Endpoints
const getPackages = () => callApi({ url: "/packages", method: "GET" });
const getPackageById = (id) => callApi({ url: `/packages/${id}`, method: "GET" });
const getPackageByInvoiceId = (id) => callApi({ url: `/packages/invoice/${id}`, method: "GET" });
const addPackage = (payload) => callApi({ url: `/packages`, method: "POST", payload });
const updatePackage = ({ id, payload }) =>
  callApi({ url: `/packages/${id}`, method: "PUT", payload });
const deletePackage = (id) => callApi({ url: `/packages/${id}`, method: "DELETE" });

// Packing Lists Endpoints
const getPackingLists = () => callApi({ url: "/packing-lists", method: "GET" });
const getPackingListById = (id) => callApi({ url: `/packing-lists/${id}`, method: "GET" });
const getPackingListByInvoiceId = (id) =>
  callApi({ url: `/packing-lists/invoice/${id}`, method: "GET" });
const addPackingList = (payload) => callApi({ url: `/packing-lists`, method: "POST", payload });
const updatePackingList = ({ id, payload }) =>
  callApi({ url: `/packing-lists/${id}`, method: "PUT", payload });
const deletePackingList = (id) => callApi({ url: `/packing-lists/${id}`, method: "DELETE" });

// Upload
const uploadFile = (payload) =>
  callApi({ url: `/file/upload`, method: "POST", type: "formData", payload });

export const generatePDF = (payload, params) =>
  callApi({
    customURL: `${process.env.REACT_APP_PDF_URL}/generate-pdf`,
    method: "POST",
    payload,
  });

export {
  signIn,
  refreshToken,
  getUsers,
  getUserById,
  addUser,
  updateUser,
  deleteUser,
  getCompanies,
  getCompanyById,
  addCompany,
  updateCompany,
  deleteCompany,
  getPackages,
  getPackageById,
  addPackage,
  updatePackage,
  deletePackage,
  getPackingLists,
  getPackingListById,
  addPackingList,
  updatePackingList,
  deletePackingList,
  getPaymentTerms,
  getPaymentTermById,
  addPaymentTerm,
  updatePaymentTerm,
  deletePaymentTerm,
  getProducts,
  getProductById,
  addProduct,
  getPackingListByInvoiceId,
  updateProduct,
  deleteProduct,
  getCommercialInvoices,
  getCommercialInvoiceById,
  addCommercialInvoice,
  updateCommercialInvoice,
  deleteCommercialInvoice,
  uploadFile,
  getPackageByInvoiceId,
  uploadCommercialInvoice,
  getHistoricalInvoiceStatus,
  updateCommercialInvoiceStatus,
};
