/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useQuery } from "@tanstack/react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useMemo } from "react";
import { getCommercialInvoices } from "services/api";
import { getPackages } from "services/api";

// Data
import { getProducts } from "services/api";
import ProductActions from "../commercial-invoices/components/ProductActions";
import { Stack } from "@mui/material";

function Products() {
  const { data, isLoading } = useQuery({ queryKey: ["products"], queryFn: getProducts });
  const { data: packages, isLoading: loadPackages } = useQuery({
    queryKey: ["packages"],
    queryFn: getPackages,
  });

  const packageData = useMemo(() => {
    const result = {};
    if (!packages?.response?.data) {
      return result;
    }

    for (const pack of packages?.response?.data) {
      result[pack.id] = `${pack.id} (${pack.quantity} ${pack.name})`;
    }
    return result;
  }, [packages?.response?.data]);

  const { data: invoices, isLoading: loadInvoices } = useQuery({
    queryKey: ["commercialInvoices"],
    queryFn: getCommercialInvoices,
  });

  const invoiceData = useMemo(() => {
    const result = {};
    if (!invoices?.response?.data) {
      return result;
    }

    for (const invoice of invoices?.response?.data) {
      result[invoice.id] = invoice;
    }
    return result;
  }, [invoices?.response?.data]);

  const dataTable = useMemo(() => {
    const table = {
      columns: [
        {
          Header: "product id",
          accessor: "id",
          width: "10%",
        },
        {
          Header: "invoice no",
          accessor: "invoice_no",
          width: "10%",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.invoice_no}
            </MDTypography>
          ),
        },
        {
          Header: "company",
          accessor: "receiver_name",
          width: "10%",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.receiver_name}
            </MDTypography>
          ),
        },
        {
          Header: "name",
          accessor: "name",
          width: "20%",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.name}
            </MDTypography>
          ),
        },
        {
          Header: "quantity",
          accessor: "quantity",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.quantity} {data?.row?.original?.quantity_name}
            </MDTypography>
          ),
        },
        {
          Header: "unit price",
          accessor: "unit_price",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.unit_price} {data?.row?.original?.unit_price_name}
            </MDTypography>
          ),
        },
        {
          Header: "weight",
          accessor: "nett_weight",
          Cell: (data) => (
            <Stack>
              <MDTypography component="span" fontSize={13}>
                Nett : {data?.row?.original?.nett_weight} KG, Gross:
                {data?.row?.original?.gross_weight} KG
              </MDTypography>
            </Stack>
          ),
        },

        {
          Header: "Actions",
          accessor: "actions",
          width: "10%",
          Cell: (data) => <ProductActions rowData={data?.row?.original} />,
        },
      ],
      rows: [],
    };

    if (!data?.response?.data || !packages?.response?.data || !invoices?.response?.data)
      return table;
    table.rows = data?.response?.data?.map((row) => ({
      ...row,
      invoice_no: invoiceData[row?.commercial_invoice_id]?.invoice_no || "",
      receiver_name: invoiceData[row?.commercial_invoice_id]?.receiver_name || "",
    }));
    return table;
  }, [data?.response?.data, packageData, invoiceData]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Products
              </MDTypography>
              <MDTypography variant="button" color="text">
                All Products Data
              </MDTypography>
            </MDBox>
            <DataTable table={dataTable} isLoading={isLoading || loadPackages} canSearch />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default Products;
