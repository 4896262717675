import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import ThemedIconButton from "components/UI/ThemedIconButton";
import ThemedBox from "components/UI/ThemedBox";

const styles = (theme) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  maxHeight: "85vh",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.2)", // Corrected boxShadow value
  borderRadius: "20px 20px 0 0",
  outline: "none",
  padding: 3,
  transition: "all 0.5s ease-in-out",
  overflowY: "auto",
  [theme.breakpoints.up("md")]: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    maxWidth: 650,
    borderRadius: 5,
    bottom: "auto",
    minHeight: "none",
    width: "none",
  },
});

const MDModal = ({ children, title, open = false, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ThemedBox sx={styles}>
        <ThemedIconButton onClick={handleClose} sx={{ position: "absolute", right: 10, top: 4 }}>
          <Close />
        </ThemedIconButton>
        <MDTypography sx fontSize={16}>
          {title}
        </MDTypography>
        <Divider />
        <MDBox
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            p: 1,
          }}
        >
          {children}
        </MDBox>
      </ThemedBox>
    </Modal>
  );
};

MDModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MDModal;
