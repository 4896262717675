import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { packingListData, addPackingListPayload } from "./formData";
import { Stack } from "@mui/material";
import { addPackingList } from "services/api";
import MDButton from "components/MDButton";
import { FileOpen } from "@mui/icons-material";
import VisuallyHiddenInput from "layouts/logistics/import/commercial-invoices/components/VisuallyHiddenInput";
import { uploadFile } from "services/api";
import { useGlobalStore } from "store";
import MDTypography from "components/MDTypography";

function AddPackingListModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "addPackingList";
  const { sidenavColor } = useGlobalStore();
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [packingList, setPackingList] = useState(addPackingListPayload);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) setFile(selectedFile);
  };

  const { mutate: onUploadPackingList, isPending: pendingUpload } = useMutation({
    mutationFn: uploadFile,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(res?.response?.response_message);
        return;
      }
      mutate({
        ...packingList,
        packing_list_file: res?.response?.data,
        total_packages: Number(packingList.total_packages),
        commercial_invoice_id: Number(packingList.commercial_invoice_id),
      });
      setFile(null);
    },
    onError: (err) => {
      toast.error(err?.response);
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: addPackingList,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["packingList", Number(data?.id)] });
      toast.success("new packing list was added successfully");
      setPackingList(addPackingListPayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(packingList)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    if (!file) return toast.error("file is required");

    const formData = new FormData();
    formData.append("file", file);
    onUploadPackingList(formData);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setPackingList({ ...packingList, [name]: value });
  };

  useEffect(() => {
    setPackingList({
      ...packingList,
      commercial_invoice_id: data?.id,
    });
  }, [data]);

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Add Packing List">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(packingList).map(([key, value]) => (
            <MDInput
              key={key}
              value={value}
              variant="standard"
              type={packingListData[key].type}
              label={packingListData[key].label}
              fullWidth
              name={key}
              disabled={key === "commercial_invoice_id"}
              onChange={handleChangeInput}
            />
          ))}
          {file && <MDTypography fontSize={13}>Selected File: {file?.name}</MDTypography>}
          <MDButton
            component="label"
            role={undefined}
            variant="gradient"
            color={sidenavColor}
            startIcon={<FileOpen />}
            tabIndex={-1}
          >
            {file ? "Change" : "Choose"} file
            <VisuallyHiddenInput type="file" accept=".pdf" onChange={handleFileChange} />
          </MDButton>
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton
            disabled={isPending || pendingUpload}
            variant="gradient"
            type="submit"
            sx={{ mt: 2 }}
          >
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

AddPackingListModal.propTypes;

export default AddPackingListModal;
