import {
  Card,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import MDTypography from "components/MDTypography";
import React, { useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FlexBox from "components/UI/FlexBox";
import MDButton from "components/MDButton";
import { useGlobalStore } from "store";
import { useModal } from "hooks/use-modal";
import { getCommercialInvoiceById, getPackingListByInvoiceId } from "services/api";
import { ArrowBackIos, CloudDownload, Edit } from "@mui/icons-material";
import GeneratePdfButton from "./GeneratePDFButton";
import CommercialInvoiceStatus from "./components/CommercialInvoiceStatus";
import { packingListTemplate } from "utils";
import { packingListData } from "examples/Modals/formData";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import Loader from "components/UI/Loader";

const borderAll = { border: "1px solid #ddd" };
const defaultColumn = { fontSize: 13 };
const defaulColumnTitle = {
  fontSize: 20,
  fontWeight: "bold",
  textTransform: "uppercase",
};
const defaultColumnSubTitle = {
  fontSize: 14,
  fontWeight: "bold",
};

const DetailCommercialInvoice = () => {
  const tableRef = useRef(null);
  const { onOpen } = useModal();
  const { sidenavColor, userLoggedIn } = useGlobalStore();
  const params = useParams();
  const navigate = useNavigate();

  const { data: iData, isLoading: loadInvoice } = useQuery({
    queryKey: ["invoice", Number(params?.id)],
    queryFn: () => getCommercialInvoiceById(params?.id),
  });

  const { data: packingList, isLoading: loadPackingList } = useQuery({
    queryKey: ["packingList", Number(params?.id)],
    queryFn: () => getPackingListByInvoiceId(params?.id),
  });

  const isLoading = loadInvoice || loadPackingList;

  const invoice = useMemo(() => {
    return iData?.response?.data;
  }, [iData?.response?.data]);

  return (
    <Stack spacing={2} pt={5} pb={3}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <MDButton
          onClick={() => navigate(-1)}
          variant="gradient"
          color={sidenavColor}
          startIcon={<ArrowBackIos />}
        >
          Back
        </MDButton>
        <MDButton
          onClick={() =>
            navigate(`/logistics/import/commercial-invoices/edit/${invoice?.id || params?.id}`)
          }
          variant="outlined"
          color={sidenavColor}
          startIcon={<Edit />}
        >
          Edit Invoice
        </MDButton>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CommercialInvoiceStatus
            currentStatus={invoice?.status}
            invoiceNumber={invoice?.invoice_no}
            startDate={invoice?.created_at}
          />
          <Card>
            <TableContainer
              component={Paper}
              sx={{ p: 5, boxShadow: "none", borderRadius: 0 }}
              ref={tableRef}
              id="commercial-invoice"
            >
              <Table sx={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4} width="50%">
                      <MDTypography sx={defaultColumnSubTitle}>Issuer :</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={6} rowSpan={6} align="center" width="49%">
                      <MDTypography sx={defaulColumnTitle}>商 业 发 票</MDTypography>
                      <MDTypography sx={defaulColumnTitle}>Commercial Invoice</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4} rowSpan={3}>
                      <Stack spacing={1}>
                        <MDTypography sx={defaultColumn}>{invoice?.issuer_name}</MDTypography>
                        <MDTypography sx={defaultColumn}>{invoice?.issuer_address}</MDTypography>
                        <MDTypography sx={defaultColumn}>{invoice?.issuer_phone}</MDTypography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>{/* <TableCell sx={borderAll} /> */}</TableRow>
                  <TableRow>{/* <TableCell sx={borderAll} /> */}</TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4}>
                      <MDTypography sx={defaultColumnSubTitle}>To :</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4} rowSpan={3}>
                      <Stack spacing={1}>
                        <MDTypography sx={defaultColumn}>{invoice?.receiver_name}</MDTypography>
                        <MDTypography sx={defaultColumn}>{invoice?.receiver_address}</MDTypography>
                        <MDTypography sx={defaultColumn}>{invoice?.receiver_phone}</MDTypography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumnSubTitle}>No.</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumnSubTitle}>Date</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumn}>{invoice?.invoice_no}</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumn}>
                        {new Date(invoice?.date)?.toLocaleDateString("id")}
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4} rowSpan={2}>
                      <MDTypography sx={defaultColumnSubTitle}>Transport Details :</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumnSubTitle}>S/C No.</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumnSubTitle}>L/C No.</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={3}>
                      <MDTypography sx={defaultColumn}>{invoice?.no_sc}</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={3}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={4} rowSpan={2} align="center">
                      <MDTypography sx={{ ...defaultColumn, textTransform: "uppercase" }}>
                        {invoice?.transport_detail}
                      </MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={6}>
                      <MDTypography sx={defaultColumnSubTitle}>Terms Of Payment</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={6}>
                      <MDTypography sx={defaultColumn}>{invoice?.payment_term?.name}</MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={2} width="20%" align="center">
                      <MDTypography sx={defaultColumnSubTitle}>Marks and Numbers</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} align="center">
                      <MDTypography sx={defaultColumnSubTitle}>Description Of Goods</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll}>
                      <MDTypography sx={defaultColumnSubTitle}>HS Code</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={2} align="center" width="17%">
                      <MDTypography sx={defaultColumnSubTitle}>Quantity</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={2} align="center">
                      <MDTypography sx={defaultColumnSubTitle}>Unit Price</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={2} align="center">
                      <MDTypography sx={defaultColumnSubTitle}>Total Price</MDTypography>
                    </TableCell>
                  </TableRow>
                  {invoice?.products?.map((product, idx) => (
                    <TableRow key={product?.id}>
                      <TableCell sx={borderAll} width="10%">
                        <MDTypography sx={defaultColumn}>{idx === 0 ? "N/M" : ""}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}></TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.name}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.hs_code}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.quantity}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.quantity_name}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.unit_price}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.unit_price_name}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.total_price}</MDTypography>
                      </TableCell>
                      <TableCell sx={borderAll}>
                        <MDTypography sx={defaultColumn}>{product?.total_price_name}</MDTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={borderAll} colSpan={10} height={100}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={borderAll} />
                    <TableCell sx={borderAll} />
                    <TableCell sx={borderAll} align="right">
                      <MDTypography sx={defaultColumnSubTitle}>Total</MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll} colSpan={5} />
                    <TableCell sx={borderAll}>
                      <MDTypography sx={defaultColumnSubTitle}>
                        {invoice?.products?.reduce((acc, curr) => acc + curr?.total_price, 0)}
                      </MDTypography>
                    </TableCell>
                    <TableCell sx={borderAll}>
                      <MDTypography sx={defaultColumnSubTitle}>RMB</MDTypography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <FlexBox justifyContent="end" px={5} pb={5} mt={2}>
              {invoice?.status === 2 && (
                <GeneratePdfButton
                  contentRef={tableRef}
                  filename={`commercial-invoice-${invoice?.invoice_no}`}
                />
              )}
              {(invoice?.status === 0 || invoice?.status === 1) &&
                userLoggedIn?.authUser?.role === "admin" && (
                  <MDButton
                    onClick={() => onOpen("updateStatus", invoice)}
                    variant="outlined"
                    color={sidenavColor}
                  >
                    Update Status
                  </MDButton>
                )}
            </FlexBox>
          </Card>
          {invoice?.status === 2 && (
            <Card sx={{ p: 5 }}>
              <MDTypography fontWeight="bold" mb={1}>
                Packing List
              </MDTypography>
              {packingList?.response?.data ? (
                <>
                  <Stack spacing={1}>
                    {Object.entries(packingList?.response?.data)?.map(
                      ([key, value]) =>
                        packingListData[key] && (
                          <Grid container key={key}>
                            <Grid item lg={2}>
                              <MDTypography sx={{ fontSize: 14 }}>
                                {packingListData[key]?.label}
                              </MDTypography>
                            </Grid>
                            <Grid item lg={1}>
                              <MDTypography sx={{ fontSize: 14 }}>:</MDTypography>
                            </Grid>
                            <Grid item lg={9}>
                              <MDTypography
                                sx={{
                                  fontSize: 14,
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {value}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        )
                    )}
                    <Grid container>
                      <Grid item lg={2}>
                        <MDTypography sx={{ fontSize: 14 }}>Uploded File</MDTypography>
                      </Grid>
                      <Grid item lg={1}>
                        <MDTypography sx={{ fontSize: 14 }}>:</MDTypography>
                      </Grid>
                      <Grid item lg={9}>
                        {
                          <MDTypography sx={{ fontSize: 14 }}>
                            {packingList?.response?.data?.packing_list_file}
                          </MDTypography>
                        }
                      </Grid>
                    </Grid>
                  </Stack>
                  <FlexBox justifyContent="center" mt={2} gap={1}>
                    <MDButton
                      component="a"
                      variant="gradient"
                      color={sidenavColor}
                      startIcon={<CloudDownload />}
                      download
                      href={packingList?.response?.data?.packing_list_file}
                    >
                      Download Packing List
                    </MDButton>
                    <MDTypography sx={{ fontSize: 13 }}>- OR -</MDTypography>
                    <MDButton
                      variant="outlined"
                      color={sidenavColor}
                      onClick={() => onOpen("updatePackingList", packingList?.response?.data)}
                      startIcon={<Edit />}
                    >
                      Edit Packing List
                    </MDButton>
                  </FlexBox>
                </>
              ) : (
                <FlexBox justifyContent="center" flexDirection="column">
                  <MDTypography sx={{ fontSize: 14, my: 2 }}>
                    Packing List Not Available
                  </MDTypography>
                  <FlexBox justifyContent="center">
                    <MDButton
                      variant="gradient"
                      color={sidenavColor}
                      onClick={() => onOpen("addPackingList", { id: params?.id })}
                    >
                      Create Packing List
                    </MDButton>
                    <MDTypography sx={{ mx: 2, fontSize: 14 }}>- OR -</MDTypography>
                    <MDButton
                      variant="outlined"
                      color={sidenavColor}
                      onClick={() => packingListTemplate(invoice)}
                    >
                      Download Template
                    </MDButton>
                  </FlexBox>
                </FlexBox>
              )}
            </Card>
          )}
        </>
      )}
    </Stack>
  );
};

export default DetailCommercialInvoice;
