import { InputLabel, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import React from "react";

const MDCustomDatePicker = ({
  value = new Date(),
  onChange = () => {},
  customLabel,
  label,
  inputFormat = "dd/MM/yyyy",
  disableDate,
}) => {
  return (
    <Stack spacing={1} fullWidth>
      {customLabel && <InputLabel>{customLabel}</InputLabel>}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          shouldDisableDate={disableDate}
          inputFormat={inputFormat}
          onChange={onChange}
          value={value}
          slotProps={{
            textField: {
              error: false,
              variant: "standard",
            },
          }}
          label={label}
        />
      </LocalizationProvider>
    </Stack>
  );
};

MDCustomDatePicker.propTypes;

export default MDCustomDatePicker;
