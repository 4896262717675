/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useQuery } from "@tanstack/react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useMemo } from "react";
import { getUsers } from "services/api";
import UserActions from "./UserActions";
import MDButton from "components/MDButton";
import { useGlobalStore } from "store";
import FlexBox from "components/UI/FlexBox";
import { useModal } from "hooks/use-modal";

import React from "react";
import { convertBase64 } from "utils";
import Cookies from "js-cookie";

const DownloadButton = () => {
  const handleDownload = async () => {
    try {
      // Ganti URL endpoint dengan endpoint API Anda
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/commercial-invoices/csv/sample`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${convertBase64("decode", Cookies.get("access_token"))}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file");
      }

      // Ambil blob dari response
      const blob = await response.blob();

      // Buat URL untuk blob
      const url = window.URL.createObjectURL(blob);

      // Buat elemen <a> untuk mengunduh file
      const a = document.createElement("a");
      a.href = url;
      a.download = "CI_SAMPLE.csv"; // Nama file yang akan diunduh
      document.body.appendChild(a);
      a.click();

      // Bersihkan
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return <MDButton onClick={handleDownload}>Download CSV</MDButton>;
};

function Users() {
  const { data, isLoading } = useQuery({ queryKey: ["users"], queryFn: getUsers });

  const { onOpen } = useModal();
  const { sidenavColor } = useGlobalStore();
  const dataTable = useMemo(() => {
    const table = {
      columns: [
        { Header: "username", accessor: "username" },
        { Header: "email", accessor: "email" },
        { Header: "role", accessor: "role" },
        {
          Header: "Actions",
          Cell: (data) => {
            return <UserActions rowData={data.row.original} />;
          },
        },
      ],
      rows: [],
    };

    if (!data?.response?.data) return table;
    table.rows = data.response.data;
    return table;
  }, [data?.response?.data]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            {/* <DownloadButton /> */}
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Users
              </MDTypography>
              <MDTypography variant="button" color="text">
                All Users Data
              </MDTypography>
            </MDBox>
            <FlexBox px={3} justifyContent="end">
              <MDButton variant="contained" color={sidenavColor} onClick={() => onOpen("addUser")}>
                Add User
              </MDButton>
            </FlexBox>
            <DataTable table={dataTable} canSearch isLoading={isLoading} />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default Users;
