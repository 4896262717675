import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addPackage } from "services/api";
import { packageData, addPackagePayload } from "./formData";
import { Stack } from "@mui/material";

function AddPackageModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "addPackage";
  const queryClient = useQueryClient();
  const [pack, setPack] = useState(addPackagePayload);

  const { mutate, isPending } = useMutation({
    mutationFn: addPackage,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["packages"] });
      toast.success("new package was added successfully");
      setPack(addPackagePayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(pack)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    mutate({ ...pack, quantity: Number(pack.quantity), commercial_invoice_id: Number(data?.id) });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setPack({ ...pack, [name]: value });
  };

  useEffect(() => {
    if (!data?.id) return;
    setPack({ ...pack, commercial_invoice_id: data?.id });
  }, [data]);

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Add Package">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(pack).map(([key, value]) => (
            <MDInput
              key={key}
              value={value}
              variant="standard"
              type={packageData[key]?.type}
              label={packageData[key]?.label}
              fullWidth
              disabled={key === "commercial_invoice_id"}
              name={key}
              onChange={handleChangeInput}
            />
          ))}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

AddPackageModal.propTypes;

export default AddPackageModal;
