import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { packingListData, addPackingListPayload } from "./formData";
import { Stack } from "@mui/material";
import { updatePackingList } from "services/api";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FileOpen } from "@mui/icons-material";
import VisuallyHiddenInput from "layouts/logistics/import/commercial-invoices/components/VisuallyHiddenInput";
import { useGlobalStore } from "store";
import { uploadFile } from "services/api";

function UpdatePackingListModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "updatePackingList";
  const queryClient = useQueryClient();
  const { sidenavColor } = useGlobalStore();
  const [packingList, setPackingList] = useState({});
  const [file, setFile] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: updatePackingList,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({
        queryKey: ["packingList", Number(data?.commercial_invoice_id)],
      });
      queryClient.invalidateQueries({ queryKey: ["packingLists"] });
      toast.success("packing list was updated successfully");
      setPackingList({});
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const { mutate: onUploadPackingList, isPending: pendingUpload } = useMutation({
    mutationFn: uploadFile,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(res?.response?.response_message);
        return;
      }
      mutate({
        id: data?.id,
        payload: {
          ...packingList,
          packing_list_file: res?.response?.data,
          total_packages: Number(packingList.total_packages),
          commercial_invoice_id: Number(packingList.commercial_invoice_id),
        },
      });
      setFile(null);
    },
    onError: (err) => {
      toast.error(err?.response);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(packingList)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      onUploadPackingList(formData);
    } else {
      mutate({
        id: data?.id,
        payload: {
          ...packingList,
          total_packages: Number(packingList.total_packages),
          commercial_invoice_id: Number(packingList.commercial_invoice_id),
        },
      });
    }
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setPackingList({ ...packingList, [name]: value });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) setFile(selectedFile);
  };

  useEffect(() => {
    setPackingList({
      packing_list_no: data?.packing_list_no,
      packing_list_file: data?.packing_list_file,
      commercial_invoice_id: data?.commercial_invoice_id,
    });
  }, [data]);

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Edit Packing List">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(packingList).map(
            ([key, value]) =>
              packingListData[key] && (
                <MDInput
                  key={key}
                  value={value}
                  variant="standard"
                  type={packingListData[key]?.type}
                  label={packingListData[key]?.label}
                  fullWidth
                  name={key}
                  disabled={key === "commercial_invoice_id"}
                  onChange={handleChangeInput}
                />
              )
          )}
          {(file || packingList?.packing_list_file) && (
            <MDTypography fontSize={13}>
              Selected File : {file?.name || packingList?.packing_list_file}
            </MDTypography>
          )}
          <MDButton
            component="label"
            role={undefined}
            variant="gradient"
            color={sidenavColor}
            startIcon={<FileOpen />}
            tabIndex={-1}
          >
            {file || packingList?.packing_list_file ? "Change" : "Choose"} file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </MDButton>
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

UpdatePackingListModal.propTypes;

export default UpdatePackingListModal;
