// src/GeneratePdfButton.js

import React, { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ThemedButton from "components/UI/ThemedButton";
import { generatePDF } from "services/api";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useGlobalStore } from "store";

function extractCSS(node) {
  const allCSS = [...document.styleSheets]
    .map((styleSheet) => {
      try {
        return [...styleSheet.cssRules].map((rule) => rule.cssText).join("");
      } catch (e) {
        console.log("Access to stylesheet %s is denied. Ignoring...", styleSheet.href);
      }
    })
    .filter(Boolean)
    .join("");

  return allCSS;
}

const GeneratePdfButton = ({ filename }) => {
  const [loading, setLoading] = useState(false);
  const { sidenavColor } = useGlobalStore();

  const handleConvertToPdf = async () => {
    setLoading(true);
    const css = extractCSS();
    const html = document.getElementById("commercial-invoice")?.innerHTML;

    try {
      const response = await generatePDF({
        html,
        title: "Commercial Invoice",
        css,
        useDefault: true,
      });

      if (!response.ok) {
        throw new Error("Failed to generate PDF");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${filename || "commercial-invoice"}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error?.message?.toLowerCase() || "something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemedButton
      variant="outlined"
      onClick={handleConvertToPdf}
      disabled={loading}
      startIcon={
        loading ? <CircularProgress color={sidenavColor} size={12} /> : <PictureAsPdfIcon />
      }
    >
      Generate PDF
    </ThemedButton>
  );
};

GeneratePdfButton.propTypes;

export default GeneratePdfButton;
