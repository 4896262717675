import React, { useMemo } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useQuery } from "@tanstack/react-query";
import { getHistoricalInvoiceStatus } from "services/api";
import { useParams } from "react-router-dom";
import { CI_STATUS } from "constants";
import { Card, Chip, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";

function CommercialInvoiceStatus({ currentStatus, invoiceNumber, startDate }) {
  const params = useParams();
  const { data: iStatus, isLoading } = useQuery({
    queryKey: ["invoiceStatus", Number(params?.id)],
    queryFn: () => getHistoricalInvoiceStatus(params?.id),
  });

  const historicalStatus = useMemo(() => {
    if (!iStatus?.response?.data || currentStatus === 0)
      return [{ status: 0, updated_at: startDate }];
    if (currentStatus !== 0 && !iStatus?.response?.data?.find((stat) => stat.status === 0)) {
      return [{ status: 0, updated_at: startDate }, ...iStatus?.response?.data];
    }
    return iStatus?.response?.data;
  }, [iStatus?.response?.data, startDate, currentStatus]);

  return (
    <Card sx={{ width: "100%", px: 2.5, py: 3 }}>
      <MDTypography fontWeight="bold" mb={2}>
        {invoiceNumber}
      </MDTypography>
      <Stepper activeStep={historicalStatus?.length} alternativeLabel>
        {historicalStatus?.map((value, index) => {
          const isCurrent = historicalStatus?.length - 1 === index;
          return (
            <Step key={index}>
              <StepLabel>
                <MDTypography fontSize={13} fontWeight={isCurrent ? "bold" : ""}>
                  {CI_STATUS[value?.status]?.name} ({new Date(value?.updated_at)?.toDateString()})
                </MDTypography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Stack mt={2}>
        <MDTypography fontSize={14}>
          Current Status :{" "}
          {
            <Chip
              label={CI_STATUS[currentStatus]?.name}
              color={CI_STATUS[currentStatus]?.color}
              size="small"
              sx={{ color: "#fff !important" }}
            />
          }
        </MDTypography>
        <MDTypography fontSize={14}>
          Status Note : {historicalStatus[historicalStatus?.length - 1]?.note || " - "}
        </MDTypography>
        <MDTypography fontSize={14}>
          Updated By : {historicalStatus[historicalStatus?.length - 1]?.updated_by}
        </MDTypography>
        <MDTypography fontSize={14}>
          Last Update :{" "}
          {new Date(historicalStatus[historicalStatus?.length - 1]?.updated_at)?.toLocaleString(
            "id"
          )}
        </MDTypography>
      </Stack>
    </Card>
  );
}
CommercialInvoiceStatus.propTypes;
export default CommercialInvoiceStatus;
