import { AddCircle, ArrowBackIos, RemoveRedEye } from "@mui/icons-material";
import {
  Autocomplete,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import FlexBox from "components/UI/FlexBox";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalStore } from "store";
import { addInvoicePayload, invoiceData } from "./formData";
import MDCustomDatePicker from "components/MDCustomDatePicker";
import MDInputOrAutocomplete from "components/MDInputOrAutocomplete";
import MDSwitchWithLabel from "components/MDSwitchWithLabel";
import { getCompanies } from "services/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPaymentTerms } from "services/api";
import { toast } from "react-toastify";
import { useModal } from "hooks/use-modal";
import { getCommercialInvoiceById } from "services/api";
import DataTable from "examples/Tables/DataTable";
import { updateCommercialInvoice } from "services/api";
import ProductActions from "./components/ProductActions";
import CommercialInvoiceStatus from "./components/CommercialInvoiceStatus";
import Loader from "components/UI/Loader";

const EditCommercialInvoice = () => {
  const navigate = useNavigate();
  const { onOpen } = useModal();
  const [autoModeInput, setAutoModeInput] = useState({
    issuer_name: "autocomplete",
    receiver_name: "autocomplete",
    party_name: "autocomplete",
  });
  const [invoice, setInvoice] = useState(addInvoicePayload);
  const { sidenavColor, selectedInvoice } = useGlobalStore();
  const params = useParams();

  const { data: iData, isLoading: loadInvoice } = useQuery({
    queryKey: ["invoice", Number(params?.id)],
    queryFn: () => getCommercialInvoiceById(params?.id),
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleChangeAutoComplete = (e, newVal, key) => {
    if (key.includes("issuer")) {
      setInvoice({
        ...invoice,
        issuer_name: newVal,
        issuer_email: newVal?.email,
        issuer_address: newVal?.address,
        issuer_phone: newVal?.phone,
      });
      return;
    }
    if (key.includes("receiver")) {
      setInvoice({
        ...invoice,
        receiver_name: newVal,
        receiver_email: newVal?.email,
        receiver_address: newVal?.address,
        receiver_phone: newVal?.phone,
      });
      return;
    }

    if (key.includes("party")) {
      setInvoice({
        ...invoice,
        party_name: newVal,
        party_email: newVal?.email,
        party_address: newVal?.address,
        party_phone: newVal?.phone,
      });
      return;
    }
    setInvoice({ ...invoice, [key]: newVal });
  };

  const handleToggleMode = (e, key) => {
    const currentInputMode = autoModeInput[key];
    setAutoModeInput({
      ...autoModeInput,
      [key]: currentInputMode === "autocomplete" ? "input" : "autocomplete",
    });
  };

  const { data: companies, isLoading: loadCompanies } = useQuery({
    queryKey: ["companies"],
    queryFn: getCompanies,
  });
  const { data: payments, isLoading: loadPayments } = useQuery({
    queryKey: ["payments"],
    queryFn: getPaymentTerms,
  });

  const companyOptions = useMemo(() => companies?.response?.data || [], [companies]);
  const paymentOptions = useMemo(() => payments?.response?.data || [], [payments]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateCommercialInvoice,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      toast.success("invoice was updated successfully");
      setInvoice(addInvoicePayload);
      navigate(`/logistics/import/commercial-invoices/${params?.id}`);
    },
  });

  const handleSubmit = () => {
    const payload = {};
    for (const [key, value] of Object.entries(invoice)) {
      if (key.includes("name")) {
        if (!value?.name) return toast.error(`${key} is required`);
        payload[key] = value?.name;
        continue;
      }
      if (key.includes("payment")) {
        if (!value?.id) return toast.error(`${key} is required`);
        payload[key] = value?.id;
        continue;
      }
      if (typeof value === "string" && !value) {
        if (!value?.id) return toast.error(`${key} is required`);
      }
      payload[key] = value;
    }
    mutate({ id: params?.id, payload });
  };

  useEffect(() => {
    if (!iData?.response?.data) return;
    const updateData = {};
    for (const [key, value] of Object.entries(invoice)) {
      if (key.includes("name")) {
        updateData[key] = { name: iData?.response?.data[key] };
        continue;
      }
      if (key.includes("payment")) {
        updateData[key] = paymentOptions.find(
          (payment) => payment.id === iData?.response?.data?.payment_term_id
        );
        continue;
      }
      if (key.includes("date")) {
        updateData[key] = new Date(iData?.response?.data[key]);
        continue;
      }
      updateData[key] = iData?.response?.data[key];
    }

    setInvoice(updateData);
  }, [iData?.response?.data, paymentOptions]);

  const dataTable = useMemo(() => {
    const table = {
      columns: [
        {
          Header: "name",
          accessor: "name",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.name}
            </MDTypography>
          ),
        },
        {
          Header: "quantity",
          accessor: "quantity",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13}>
              {data?.row?.original?.quantity} {data?.row?.original?.quantity_name}
            </MDTypography>
          ),
        },
        {
          Header: "unit price",
          accessor: "unit_price",
          Cell: (data) => (
            <MDTypography component="span" fontSize={13} color="inherit">
              {data?.row?.original?.unit_price} {data?.row?.original?.unit_price_name}
            </MDTypography>
          ),
        },
        {
          Header: "Actions",
          accessor: "actions",
          width: "10%",
          Cell: (data) => <ProductActions rowData={data?.row?.original} />,
        },
      ],
      rows: [],
    };

    if (!iData?.response?.data) return table;
    table.rows = iData?.response?.data?.products;
    return table;
  }, [iData?.response?.data?.products]);

  const isLoading = loadInvoice || loadPayments || loadCompanies;

  return (
    <MDBox pt={5} pb={3}>
      <FlexBox justifyContent="start" mb={2}>
        <MDButton
          onClick={() => navigate(-1)}
          variant="gradient"
          color={sidenavColor}
          startIcon={<ArrowBackIos />}
        >
          Back
        </MDButton>
      </FlexBox>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CommercialInvoiceStatus
            currentStatus={iData?.response?.data?.status}
            invoiceNumber={iData?.response?.data?.invoice_no}
            startDate={iData?.response?.data?.created_at}
          />
          <Card sx={{ mt: 2 }}>
            <MDBox py={3} px={5} lineHeight={1}>
              <FlexBox justifyContent="space-between" mb={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Commercial Invoice
                </MDTypography>
              </FlexBox>
              <Grid container alignItems="flex-start" spacing={3}>
                <Grid item lg={6} xs={12}>
                  <Stack py={2} fullWidth spacing={2}>
                    {Object.entries(invoice).map(([key, value]) =>
                      key === "payment_term_id" ? (
                        <Autocomplete
                          key={key}
                          disableClearable
                          value={value}
                          options={paymentOptions}
                          disabled={loadPayments}
                          getOptionLabel={(option) => option?.name || ""}
                          onChange={(e, newVal) => handleChangeAutoComplete(e, newVal, key)}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label={invoiceData[key]?.label}
                            />
                          )}
                        />
                      ) : invoiceData[key]?.type === "date" ? (
                        <MDCustomDatePicker
                          key={key}
                          value={value}
                          label={invoiceData[key]?.label}
                          onChange={(val) => setInvoice({ ...invoice, [key]: val })}
                        />
                      ) : invoiceData[key]?.type === "checkbox" ? (
                        <MDBox key={key}>
                          <FormControlLabel
                            sx={{ mr: -1 }}
                            label={invoiceData[key]?.label}
                            control={
                              <Checkbox
                                checked={value}
                                onChange={() => {
                                  setInvoice({ ...invoice, [key]: !value });
                                }}
                              />
                            }
                          />
                        </MDBox>
                      ) : (invoiceData[key]?.isOption && !key.includes("party")) ||
                        (invoiceData[key]?.isOption &&
                          key.includes("party") &&
                          invoice["notify_party"]) ? (
                        <MDBox key={key}>
                          <MDSwitchWithLabel
                            checked={autoModeInput[key] === "autocomplete"}
                            label="Auto Mode"
                            onChange={(e) => handleToggleMode(e, key)}
                            containerProps={{
                              sx: {
                                mb: "-18px !important",
                              },
                            }}
                          />
                          <MDInputOrAutocomplete
                            key={key}
                            mode={autoModeInput[key]}
                            inputProps={{
                              value: value?.name || "",
                              onChange: handleChangeInput,
                              name: key,
                              type: invoiceData[key]?.type,
                              label: invoiceData[key]?.label,
                            }}
                            autoCompleteProps={{
                              disableClearable: true,
                              value: value,
                              options: companyOptions,
                              label: invoiceData[key]?.label,
                              disabled: loadCompanies,
                              getOptionLabel: (option) => option?.name || "",
                              onChange: (e, newVal) => handleChangeAutoComplete(e, newVal, key),
                            }}
                          />
                        </MDBox>
                      ) : (!invoiceData[key]?.isOption && !key.includes("party")) ||
                        (!invoiceData[key]?.isOption &&
                          key.includes("party") &&
                          invoice["notify_party"]) ? (
                        <MDInput
                          key={key}
                          value={value}
                          variant="standard"
                          name={key}
                          label={invoiceData[key]?.label}
                          type={invoiceData[key]?.type}
                          disabled={
                            invoiceData[key]?.parent
                              ? autoModeInput[invoiceData[key]?.parent] === "autocomplete"
                              : false
                          }
                          onChange={handleChangeInput}
                        />
                      ) : null
                    )}
                  </Stack>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Stack spacing={2}>
                    <MDTypography sx={{ fontWeight: "bold" }}>Invoice Data Preview</MDTypography>
                    <Stack spacing={1}>
                      {Object.entries(invoice).map(([key, value]) => (
                        <Grid key={key} container>
                          <Grid item lg={3}>
                            <MDTypography sx={{ fontSize: 14 }}>
                              {invoiceData[key]?.label}
                            </MDTypography>
                          </Grid>
                          <Grid item lg={1}>
                            <MDTypography sx={{ fontSize: 14 }}>:</MDTypography>
                          </Grid>
                          <Grid item lg={8}>
                            <MDTypography
                              sx={{
                                fontSize: 14,
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {key.includes("name") || key.includes("payment")
                                ? value?.name
                                : typeof value === "date"
                                ? value?.toDateString()
                                : value?.toString()}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Stack pb={2} fullWidth spacing={2}>
                <FlexBox justifyContent="space-between">
                  <MDTypography sx={{ fontWeight: "bold" }}>Products</MDTypography>
                  <MDButton
                    disabled={
                      iData?.response?.data?.status === 2 || iData?.response?.data?.status === -1
                    }
                    variant="gradient"
                    color={sidenavColor}
                    onClick={() => onOpen("addProduct", selectedInvoice)}
                  >
                    Add Product
                  </MDButton>
                </FlexBox>
                {iData?.response?.data?.products?.length === 0 ? (
                  <MDTypography sx={{ fontSize: 13, textAlign: "cen" }}>No Products</MDTypography>
                ) : (
                  <DataTable
                    entriesPerPage={{ defaultValue: 5 }}
                    table={dataTable}
                    isLoading={loadInvoice}
                  />
                )}
              </Stack>
              <FlexBox mt={5}>
                <MDButton
                  disabled={
                    isPending ||
                    iData?.response?.data?.status === 2 ||
                    iData?.response?.data?.status === -1
                  }
                  variant="gradient"
                  color={sidenavColor}
                  onClick={handleSubmit}
                >
                  Update Invoice
                </MDButton>
                <MDTypography fontSize={14} mx={1.5}>
                  - OR -
                </MDTypography>
                <MDButton
                  disabled={isPending}
                  variant="outlined"
                  color={sidenavColor}
                  startIcon={<RemoveRedEye />}
                  onClick={() =>
                    navigate(`/logistics/import/commercial-invoices/${invoice?.id || params?.id}`)
                  }
                >
                  View Invoice
                </MDButton>
              </FlexBox>
            </MDBox>
          </Card>
        </>
      )}
    </MDBox>
  );
};

export default EditCommercialInvoice;
