import MDButton from "components/MDButton";
import React, { forwardRef } from "react";
import { useGlobalStore } from "store";

const ThemedButton = forwardRef(({ children, color, ...restProps }, ref) => {
  const { sidenavColor } = useGlobalStore();
  return (
    <MDButton ref={ref} {...restProps} color={color || sidenavColor}>
      {children}
    </MDButton>
  );
});

ThemedButton.propTypes;

export default ThemedButton;
