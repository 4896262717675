import { ArrowBackIos, CloudUpload } from "@mui/icons-material";
import MDBox from "components/MDBox";
import FlexBox from "components/UI/FlexBox";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalStore } from "store";
import ManualInvoice from "./ManualInvoice";
import MDButton from "components/MDButton";
import CustomTab from "components/UI/CustomTab";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import UploadInvoice from "./UploadInvoice";
import TabPanel from "components/UI/CustomTab/TabPanel";

const tabs = [{ label: "Manual Input" }, { label: "Upload Invoice" }];

const AddNewCommercialInvoice = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (_, newVal) => setTabValue(newVal);
  const { sidenavColor } = useGlobalStore();

  return (
    <MDBox pt={6} pb={3}>
      <FlexBox justifyContent="start" mb={2}>
        <MDButton
          onClick={() => navigate(-1)}
          variant="contained"
          color={sidenavColor}
          startIcon={<ArrowBackIos />}
        >
          Back
        </MDButton>
      </FlexBox>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={8}>
          <MDTypography variant="h5" fontWeight="medium" mt={1}>
            Add New Commercial Invoice
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomTab tabs={tabs} tabValue={tabValue} handleSetTabValue={handleSetTabValue} />
        </Grid>
      </Grid>
      <TabPanel value={tabValue} index={0} icon={<CloudUpload />}>
        <ManualInvoice />
      </TabPanel>
      <TabPanel value={tabValue} index={1} icon={<CloudUpload />}>
        <UploadInvoice />
      </TabPanel>
    </MDBox>
  );
};

export default AddNewCommercialInvoice;
