import { Autocomplete, InputLabel, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

const MDInputOrAutocomplete = ({
  mode = "autocomplete",
  customLabel = "",
  inputProps = {},
  autoCompleteProps = {},
}) => {
  return (
    <Stack fullWidth spacing={1}>
      {customLabel && <InputLabel>{customLabel}</InputLabel>}
      {mode === "autocomplete" ? (
        <Autocomplete
          {...autoCompleteProps}
          renderInput={(params) => (
            <MDInput
              {...params}
              InputLabelProps={{ shrink: true }}
              label={autoCompleteProps?.label}
              variant="standard"
            />
          )}
        />
      ) : (
        <MDInput {...inputProps} variant="standard" />
      )}
    </Stack>
  );
};

MDInputOrAutocomplete.propTypes;
export default MDInputOrAutocomplete;
