export const invoiceData = {
  id: {
    label: "Invoice ID",
    type: "text",
  },
  invoice_no: {
    label: "Invoice Number",
    type: "text",
  },
  issuer_name: {
    label: "Issuer Name",
    type: "text",
    isOption: true,
  },
  issuer_address: {
    label: "Issuer Address",
    type: "text",
    parent: "issuer_name",
  },
  issuer_email: {
    label: "Issuer Email",
    type: "email",
    parent: "issuer_name",
  },
  issuer_phone: {
    label: "Issuer Phone",
    type: "number",
    parent: "issuer_name",
  },
  receiver_name: {
    label: "Receiver Name",
    type: "text",
    parent: "issuer_name",
    isOption: true,
  },
  receiver_address: {
    label: "Receiver Address",
    type: "text",
    parent: "receiver_name",
  },
  receiver_email: {
    label: "Receiver Email",
    type: "email",
    parent: "receiver_name",
  },
  receiver_phone: {
    label: "Receiver Phone",
    type: "number",
    parent: "receiver_name",
  },
  notify_party: {
    label: "Notify Party",
    type: "checkbox",
  },
  party_name: {
    label: "Party Name",
    type: "text",
    isOption: true,
  },
  party_address: {
    label: "Party Address",
    type: "text",
    parent: "party_name",
  },
  party_email: {
    label: "Party Email",
    type: "email",
    parent: "party_name",
  },
  party_phone: {
    label: "Party Phone",
    type: "number",
    parent: "party_name",
  },
  no_sc: {
    label: "No SC",
    type: "text",
  },
  date: {
    label: "Date",
    type: "date", // Tipe date untuk memilih tanggal
  },
  transport_detail: {
    label: "Transport Detail",
    type: "text",
  },
  payment_term_id: {
    label: "Payment Term",
    type: "text",
    type: "autocomplete",
  },
  payment_term: {
    label: "Payment Term",
    type: "text",
    type: "autocomplete",
  },
};

export const addInvoicePayload = {
  invoice_no: "",
  issuer_name: { name: "" },
  issuer_address: "",
  issuer_email: "",
  issuer_phone: "",
  receiver_name: { name: "" },
  receiver_address: "",
  receiver_email: "",
  receiver_phone: "",
  notify_party: true,
  party_name: { name: "" },
  party_address: "",
  party_email: "",
  party_phone: "",
  no_sc: "",
  date: "",
  transport_detail: "",
  payment_term_id: { id: "" },
};
