import { CircularProgress, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import React from "react";
import { useGlobalStore } from "store";
import FlexBox from "./FlexBox";

const Loader = () => {
  const { sidenavColor } = useGlobalStore();
  return (
    <FlexBox gap={1} justifyContent={"center"} py={10}>
      <CircularProgress size={22} color={sidenavColor} />
      <MDTypography fontSize={14}>Loading Data...</MDTypography>
    </FlexBox>
  );
};

export default Loader;
