import Cookies from "js-cookie";
import { handleExpiredToken } from "utils";
import { convertBase64 } from "utils";

const TIMEOUT_DURATION = 300000;
const CONTENT_TYPE_JSON = "application/json";

const callApi = async ({ url, method, payload, customURL, type }) => {
  if (url && url?.includes("undefined")) return;
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), TIMEOUT_DURATION);
  let body;
  let headers = new Headers();

  if (type === "formData") body = payload;
  if (url && !url?.includes("auth")) {
    headers.append(
      "Authorization",
      `Bearer ${convertBase64("decode", Cookies.get("access_token"))}`
    );
  }
  if (["POST", "PUT"].includes(method) && !type) {
    headers.append("Content-Type", CONTENT_TYPE_JSON);
    body = JSON.stringify(payload);
  }

  try {
    const res = await fetch(`${customURL || process.env.REACT_APP_BASE_URL + url}`, {
      method,
      headers,
      body,
      signal: controller.signal,
      cache: "no-store",
      redirect: "follow",
    });

    if (res.status === 401 && !url.includes("authe")) {
      console.log("triggered refresh", res.status);
      await handleExpiredToken(() => callApi({ url, method, payload, customURL, type }));
      return;
    }

    if (customURL) return res;
    const result = await res.json();

    return result;
  } catch (error) {
    throw new Error(`API call failed: ${error.message}`);
  } finally {
    clearTimeout(timeoutId);
  }
};

export { callApi };
