import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import { updateCompany } from "services/api";
import { companyData } from "./formData";

const notRenderKeys = ["id", "password", "updated_at", "created_at", "created_by", "updated_by"];

function UpdateCompanyModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "updateCompany";
  const queryClient = useQueryClient();
  const [company, setCompany] = useState({});

  useEffect(() => {
    setCompany(data);
  }, [data]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateCompany,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      toast.success("company was updated successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(company)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }
    mutate({ id: company.id, payload: company });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Edit Company">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(company)?.map(([key, value]) =>
            !notRenderKeys.includes(key) ? (
              <MDInput
                key={key}
                value={value}
                variant="standard"
                type={companyData[key]?.type}
                label={companyData[key]?.label}
                fullWidth
                name={key}
                multiline={key === "description"}
                onChange={handleChangeInput}
              />
            ) : null
          )}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

UpdateCompanyModal.propTypes;

export default UpdateCompanyModal;
