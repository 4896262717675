export const CI_STATUS = {
  "-1": { name: "Rejected", color: "error" },
  0: {
    name: "Approval",
    color: "info",
  },
  1: {
    name: "Revision",
    color: "warning",
  },
  2: {
    name: "Approved",
    color: "success",
  },
};

export const DEFAULT_BORDER_STYLE = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

export const DEFAULT_ALIGNMENT = {
  vertical: "center",
  horizontal: "center",
  wrapText: true,
};

export const CENTER_CONTENT_WRAP_STYLE = {
  alignment: DEFAULT_ALIGNMENT,
  font: { sz: 14 },
};

export const WRAP_CONTENT_STYLE = {
  alignment: { wrapText: true, vertical: "center" },
  font: { sz: 14 },
};

export const DEFAULT_HEADER_STYLE = {
  alignment: DEFAULT_ALIGNMENT,
  font: { color: { rgb: "FFFFFF" }, sz: 14 },
  fill: { fgColor: { rgb: "539165" } },
};

export const DEFAULT_FOOTER_STYLE = {
  ...CENTER_CONTENT_WRAP_STYLE,
  fill: { fgColor: { rgb: "5D9C59" }, sz: 14 },
  font: { bold: true },
};

export const SUMMARY_STYLE = {
  ...CENTER_CONTENT_WRAP_STYLE,
  font: { bold: true },
};

export const DEFAULT_CURRENCY_OPTIONS = {
  style: "currency",
  currency: "IDR",
  maximumFractionDigits: 0,
};

export const USER_MENU = [
  "/logistics/import/commercial-invoices",
  "/logistics/import/commercial-invoices/add-new",
  "/logistics/import/commercial-invoices/edit/:id",
  "/logistics/import/commercial-invoices/:id",
  "/logistics/import/products",
  "/logistics/import/packing-list",
];
export const ADMIN_MENU = [
  "/logistics/users",
  "/logistics/companies",
  "/logistics/import/commercial-invoices",
  "/logistics/import/commercial-invoices/add-new",
  "/logistics/import/commercial-invoices/edit/:id",
  "/logistics/import/commercial-invoices/:id",
  "/logistics/import/products",
  "/logistics/import/packing-list",
];
export const WAREHOUSE_MENU = ["/logistics/import/products"];

export const MENU_ACCESS = {
  user: USER_MENU,
  admin: ADMIN_MENU,
  warehouse: WAREHOUSE_MENU,
};
