import { Stack } from "@mui/material";
import { PuffLoader } from "react-spinners";

const FallbackUI = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      top={0}
      left={0}
      right={0}
      bottom={0}
      position="absolute"
      backgroundColor="#fff"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <PuffLoader size={100} color="red" />
    </Stack>
  );
};

export default FallbackUI;
