import colors from "assets/theme-dark/base/colors";
import MDBox from "components/MDBox";
import React, { forwardRef } from "react";
import { useGlobalStore } from "store";

const ThemedBox = forwardRef(({ children, ...rest }, ref) => {
  const { darkMode } = useGlobalStore();

  return (
    <MDBox {...rest} bgColor={darkMode ? colors.background.card : "white"}>
      {children}
    </MDBox>
  );
});

ThemedBox.propTypes;
export default ThemedBox;
