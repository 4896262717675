import { AppBar, Icon, Tab, Tabs } from "@mui/material";

const CustomTab = ({ tabs, tabValue, handleSetTabValue }) => {
  return (
    <AppBar position="static">
      <Tabs orientation={"horizontal"} value={tabValue} onChange={handleSetTabValue}>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            label={tab.label}
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                {tab.name}
              </Icon>
            }
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

CustomTab.propTypes;

export default CustomTab;
