/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useQuery } from "@tanstack/react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useMemo } from "react";
import { getCommercialInvoices } from "services/api";
import FlexBox from "components/UI/FlexBox";
import MDButton from "components/MDButton";
import { useGlobalStore } from "store";
import { useNavigate } from "react-router-dom";
import CommercialInvoiceActions from "./components/CommercialInvoiceActions";
import { Chip } from "@mui/material";
import { CI_STATUS } from "constants";

function CommercialInvoices() {
  const { sidenavColor } = useGlobalStore();
  const { data, isLoading } = useQuery({
    queryKey: ["commercialInvoices"],
    queryFn: getCommercialInvoices,
  });
  const navigate = useNavigate();

  const dataTable = useMemo(() => {
    const table = {
      columns: [
        { Header: "invoice id", accessor: "id" },
        { Header: "invoice no", accessor: "invoice_no" },
        { Header: "issuer", accessor: "issuer_name" },
        { Header: "receiver", accessor: "receiver_name" },
        { Header: "party", accessor: "party_name" },
        { Header: "transport", accessor: "transport_detail" },
        {
          Header: "status",
          accessor: "status",
          Cell: (data) => (
            <Chip
              label={CI_STATUS[data?.row?.original?.status]?.name}
              size="small"
              color={CI_STATUS[data?.row?.original?.status]?.color}
              sx={{ color: "#fff !important" }}
            />
          ),
        },
        {
          Header: "action",
          accessor: "action",
          Cell: (data) => <CommercialInvoiceActions rowData={data?.row?.original} />,
        },
      ],
      rows: [],
    };
    if (!data?.response?.data) return table;
    table.rows = data?.response?.data;

    return table;
  }, [data?.response?.data]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Commercial Invoices
              </MDTypography>
              <MDTypography variant="button" color="text">
                All Invoices Data
              </MDTypography>
            </MDBox>
            <FlexBox px={3} justifyContent="end">
              <MDButton
                variant="contained"
                color={sidenavColor}
                onClick={() => navigate("/logistics/import/commercial-invoices/add-new")}
              >
                Add Invoice
              </MDButton>
            </FlexBox>
            <DataTable table={dataTable} isLoading={isLoading} canSearch />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default CommercialInvoices;
