import MDInput from "components/MDInput";
import * as React from "react";
import { NumberFormatBase } from "react-number-format";

const format = (numStr) => {
  if (numStr === "") return "";
  return new Intl.NumberFormat("CN", {
    style: "currency",
    currency: "RMB",
    maximumFractionDigits: 0,
  }).format(numStr);
};

const NumberFormatCustom = React.forwardRef(function (props, ref) {
  const { name, onChange, ...rest } = props;

  return (
    <NumberFormatBase
      {...rest}
      value={Number(rest.value)}
      format={format}
      getInputRef={ref}
      onValueChange={(values) => {
        console.log("values", values);
        onChange({
          target: {
            name: name,
            value: values.floatValue,
          },
        });
      }}
    />
  );
});

NumberFormatCustom.propTypes;

const CurrencyInput = ({ ...restProps }) => {
  return (
    <MDInput
      {...restProps}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export default CurrencyInput;
