import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addProduct } from "services/api";
import { productData, addProductPayload } from "./formData";
import { Autocomplete, Stack } from "@mui/material";

import { getPackageByInvoiceId } from "services/api";
import { updateProduct } from "services/api";

function UpdateProductModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "updateProduct";
  const queryClient = useQueryClient();
  const [product, setProduct] = useState(addProductPayload);

  const { mutate, isPending } = useMutation({
    mutationFn: updateProduct,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["invoice", data?.commercial_invoice_id] });
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast.success("product was updated successfully");
      setProduct(addProductPayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  // const { data: packages, isLoading: loadPackages } = useQuery({
  //   queryKey: ["packages", data?.commercial_invoice_id],
  //   queryFn: () => getPackageByInvoiceId(data?.commercial_invoice_id),
  //   enabled: Boolean(data?.commercial_invoice_id),
  // });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(product)) {
      if (key !== "image" && !val) {
        toast.error(`${key} is required`);
        return;
      }
    }

    mutate({
      id: data?.id,
      payload: {
        ...product,
        package_id: product.package_id?.id,
        hs_code: Number(product.hs_code),
        quantity: Number(product.quantity),
        gross_weight: Number(product.gross_weight),
        nett_weight: Number(product.nett_weight),
        unit_price: Number(product.unit_price),
        total_price: Number(product.total_price),
      },
    });
  };

  const handleSelectOption = (_, newVal) => {
    setProduct({ ...product, package_id: newVal });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  useEffect(() => {
    setProduct({
      ...product,
      name: data?.name,
      hs_code: data?.hs_code,
      quantity: data?.quantity,
      quantity_name: data?.quantity_name,
      gross_weight: data?.gross_weight,
      nett_weight: data?.nett_weight,
      commercial_invoice_id: data?.commercial_invoice_id,
      unit_price: data?.unit_price,
      total_price: data?.total_price,
      unit_price_name: "RMB",
      total_price_name: "RMB",
    });
  }, [data]);

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Edit Product">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          {Object.entries(product).map(([key, value]) =>
            productData[key]?.type === "autocomplete" ? (
              <Autocomplete
                onChange={handleSelectOption}
                key={key}
                value={value || packages?.response?.data[0] || ""}
                disabled={loadPackages}
                options={packages?.response?.data || []}
                getOptionLabel={(option) => `(${option?.id}) ${option?.name}`}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    label={productData[key]?.label}
                  />
                )}
              />
            ) : (
              <MDInput
                key={key}
                value={value}
                variant="standard"
                type={productData[key]?.type}
                label={productData[key]?.label}
                fullWidth
                name={key}
                disabled={key === "commercial_invoice_id"}
                onChange={handleChangeInput}
              />
            )
          )}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

UpdateProductModal.propTypes;

export default UpdateProductModal;
