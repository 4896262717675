import { useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import React, { forwardRef } from "react";

const FlexBox = forwardRef(({ children, ...restProps }, ref) => {
  const matchesMd = useMediaQuery("(max-width:600px)");

  return (
    <MDBox
      ref={ref}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={matchesMd ? "column" : "row"}
      {...restProps}
    >
      {children}
    </MDBox>
  );
});

FlexBox.propTypes;
export default FlexBox;
