import { MENU_ACCESS } from "constants";
import { DEFAULT_BORDER_STYLE } from "constants";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { refreshToken } from "services/api";
import { utils, writeFile } from "xlsx-js-style";

export const generateArray = (length, value) => Array(length).fill(value);

export const exportRagunanTemlplate = (wb, data) => {
  let ws;
  let populateData = [];
  const userData = data.find((d) => d?.user);

  for (let [index, value] of data.entries()) {
    let fillData = [];
    for (let i = 0; i < 13; i++) {
      if (i === 0) {
        fillData.push({
          v: index + 1,
          s: CENTER_CONTENT_WRAP_STYLE,
        });
        continue;
      }
      if (i === 1) {
        fillData.push({
          v: value?.date,
          s: CENTER_CONTENT_WRAP_STYLE,
        });
        continue;
      }
      if (i === 2) {
        fillData.push({
          v: value?.project || value?.value || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            alignment: { vertical: "center", wrapText: true },
            font: {},
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 3) {
        fillData.push({
          v: value?.work_mode === "WFO" ? "GTI" : value?.work_mode || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: {},
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 4) {
        fillData.push({
          v: value?.clock_in || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: {},
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 6) {
        fillData.push({
          v: value?.clock_out || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            font: {},
            fill: value?.value
              ? { fgColor: { rgb: "FFED00" } }
              : value?.isLtEightHours
              ? { fgColor: { rgb: "F45050" } }
              : undefined,
          },
        });
        continue;
      }

      if (i === 10) {
        fillData.push({
          v: value?.hoursPerDay || "",
          s: {
            ...CENTER_CONTENT_WRAP_STYLE,
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      if (i === 12) {
        fillData.push({
          v: value?.activity || "",
          s: {
            ...WRAP_CONTENT_STYLE,
            fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
          },
        });
        continue;
      }
      fillData.push({
        v: "",
        s: {
          ...CENTER_CONTENT_WRAP_STYLE,
          fill: value?.value ? { fgColor: { rgb: "FFED00" } } : undefined,
        },
      });
    }
    populateData.push(fillData);
  }

  const aoaData = [
    [
      {
        v: "NAME",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      {
        v: userData?.user,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      "",
      {
        v: "JOB",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: userData?.role,
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12 },
        },
      },
      "",
      "",
      "",
      "",
      {
        v: "PT SHARING VISION INDONESIA",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12 },
        },
      },
    ],
    [
      {
        v: "NO",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11 },
        },
      },
      {
        v: "DATE",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11 },
        },
      },
      {
        v: "NAME OF PROJECT",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: "LOCATION",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11 },
        },
      },
      {
        v: "WORKING HOUR",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: "OVERTIME",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      "",
      {
        v: "TOTAL",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 11, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "000000" } },
        },
      },
      "",
      {
        v: "ACTIVITY/REMARK",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 12 },
        },
      },
    ],
    [
      ...generateArray(4, ""),
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10 },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "Start",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "-",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "End",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "Hour",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      {
        v: "OT",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 10, bold: true },
        },
      },
      "",
    ],
    ...populateData,
    [
      { v: "TOTAL HOURS", s: DEFAULT_FOOTER_STYLE },
      ...generateArray(10, ""),
      {
        v: convertToHoursMinutes(
          data.filter((d) => d?.timeDiff).reduce((acc, d) => acc + d.timeDiff, 0)
        ),
        s: DEFAULT_FOOTER_STYLE,
      },
      { v: "PERNYATAAN SAYA", s: DEFAULT_FOOTER_STYLE },
    ],
    [
      { v: "A", s: SUMMARY_STYLE },
      { v: "Ijin" },
      ...generateArray(9, ""),
      { v: "0", s: SUMMARY_STYLE },
      {
        v: "Time report ini saya buat dengan sunguh-sungguh dan sebenarnya sesuai dengan nilai-nilai etika dan profesionalisme perusahaan dengan penempatan di BRI",
        s: SUMMARY_STYLE,
      },
    ],
    [
      { v: "B", s: SUMMARY_STYLE },
      { v: "Sakit" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => d?.work_mode?.includes("Sakit")).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "C", s: SUMMARY_STYLE },
      { v: "Cuti" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => d?.work_mode?.includes("Cuti")).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],

    [
      { v: "D", s: SUMMARY_STYLE },
      { v: "Total Jam Kerja Normal" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => !d?.value).length * 8,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "E", s: SUMMARY_STYLE },
      { v: "Total Hari Kerja" },
      ...generateArray(9, ""),
      {
        v: data.filter((d) => !d?.value).length,
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "F", s: SUMMARY_STYLE },
      { v: "Total Jam Kerja Lembur" },
      ...generateArray(9, ""),
      {
        v: "0",
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [
      { v: "G", s: SUMMARY_STYLE },
      { v: "Persentase Keterlambatan" },
      ...generateArray(9, ""),
      {
        v: "0%",
        s: SUMMARY_STYLE,
      },
      "",
    ],
    [{ v: "H", s: SUMMARY_STYLE }, { v: "Persentase Doa Pagi" }, ...generateArray(9, ""), "", ""],
  ];

  ws = utils.aoa_to_sheet(aoaData);
  const colWidth = [30, 80, 95, 75, ...generateArray(8, 70), 350];

  colWidth.forEach((width, columnIndex) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][columnIndex] = { hidden: false, wpx: width };
  });
  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][0] = { hpt: 25, hpx: 25 };
  ws["!rows"][1] = { hpt: 25, hpx: 25 };
  ws["!rows"][2] = { hpt: 20, hpx: 20 };
  ws["!rows"][data.length + 3] = { hpt: 25, hpx: 25 };

  // Border All
  const startRow = 0; // Baris awal range
  const endRow = aoaData.length; // Baris akhir range
  const startCol = 0; // Kolom awal range
  const endCol = 12; // Kolom akhir range

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cellAddress = utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddress];
      if (!cell) continue;

      cell.s = {
        ...cell.s,
        border: DEFAULT_BORDER_STYLE,
      };
    }
  }

  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(utils.decode_range("A1:B1"));
  ws["!merges"].push(utils.decode_range("C1:D1"));
  ws["!merges"].push(utils.decode_range("E1:G1"));
  ws["!merges"].push(utils.decode_range("H1:L1"));
  ws["!merges"].push(utils.decode_range("A2:A3"));
  ws["!merges"].push(utils.decode_range("B2:B3"));
  ws["!merges"].push(utils.decode_range("C2:C3"));
  ws["!merges"].push(utils.decode_range("D2:D3"));
  ws["!merges"].push(utils.decode_range("E2:G2"));
  ws["!merges"].push(utils.decode_range("H2:J2"));
  ws["!merges"].push(utils.decode_range("K2:L2"));
  ws["!merges"].push(utils.decode_range("M2:M3"));
  for (const [index, value] of data.entries()) {
    if (value?.value) ws["!merges"].push(utils.decode_range(`C${index + 4}: M${index + 4}`));
  }
  ws["!merges"].push(utils.decode_range(`A${data.length + 4}:K${data.length + 4}`));

  for (let i = 5; i <= 12; i++) {
    ws["!merges"].push(utils.decode_range(`B${data.length + i}:C${data.length + i}`));
  }
  ws["!merges"].push(utils.decode_range(`D${data.length + 5}:K${data.length + 12}`));
  ws["!merges"].push(utils.decode_range(`M${data.length + 5}:M${data.length + 12}`));

  utils.book_append_sheet(wb, ws, userData?.user?.slice(0, 30));
};

export const packingListTemplate = (data) => {
  let ws;
  const wb = utils.book_new();
  const currentData = [...data.products];
  currentData.shift();
  const populateData = [];

  for (const product of currentData) {
    populateData.push([
      ...generateArray(4, ""),
      {
        v: `${product?.name}`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
      {
        v: `${product?.quantity}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${product?.quantity_name}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${product?.gross_weight}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${product?.nett_weight}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
    ]);
  }

  const aoaData = [
    [
      {
        v: "Issuer:",
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      ...generateArray(5, ""),
      {
        v: "PACKING LIST",
        s: {
          alignment: { vertical: "center", horizontal: "center" },
          font: { sz: 16, bold: true },
        },
      },
      ...generateArray(4, ""),
    ],
    [
      {
        v: data.issuer_name,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: data.issuer_address,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: data.issuer_phone,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: "To:",
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: data.receiver_name,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: data.receiver_address,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(10, ""),
    ],
    [
      {
        v: data.receiver_phone,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(5, ""),
      {
        v: `S/C No : ${data.no_sc}`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      ...generateArray(2, ""),
      {
        v: `Date :`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      {
        v: `${new Date(data.date).toLocaleDateString("id")}`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
    ],
    [
      {
        v: "Mark and Numbers",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(3, ""),
      {
        v: "Description of Goods Numbers and kind of packages",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },

      ...generateArray(1, ""),
      {
        v: `Quantity`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
      {
        v: `GR.WT(@,TOTAL) KGS`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `NT.WT(@,TOTAL) KGS`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
    ],
    [
      {
        v: "N/M",
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 12 },
        },
      },
      ...generateArray(3, ""),
      {
        v: `${data?.products[0]?.name}`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
      {
        v: `${data?.products[0]?.quantity}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${data?.products[0]?.quantity_name}`,
        s: {
          alignment: {
            vertical: "center",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${data?.products[0]?.gross_weight}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      {
        v: `${data?.products[0]?.nett_weight}`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 11 },
        },
      },
      ...generateArray(1, ""),
    ],
    ...populateData,
    [...generateArray(11, "")],
    [
      ...generateArray(4, ""),
      {
        v: `TOTAL`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(3, ""),
      {
        v: `${data?.products?.reduce((acc, curr) => acc + curr?.gross_weight, 0)} KG`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      {
        v: `${data?.products?.reduce((acc, curr) => acc + curr?.nett_weight, 0)} KG`,
        s: {
          alignment: {
            vertical: "center",
            horizontal: "right",
            wrapText: true,
          },
          font: { sz: 12, bold: true },
        },
      },
      ...generateArray(1, ""),
    ],
  ];
  ws = utils.aoa_to_sheet(aoaData);
  // Border All
  const startRow = 0; // Baris awal range
  const endRow = aoaData.length; // Baris akhir range
  const startCol = 0; // Kolom awal range
  const endCol = 11; // Kolom akhir range

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cellAddress = utils.encode_cell({ r: row, c: col });
      const cell = ws[cellAddress];
      if (!cell) continue;
      cell.s = {
        ...cell.s,
        border: DEFAULT_BORDER_STYLE,
      };
    }
  }

  utils.book_append_sheet(wb, ws);
  const colWidth = [80, 50, 50, 80, 150, 80, 70, 60, 120, 50, 70];
  colWidth.forEach((width, columnIndex) => {
    ws["!cols"] = ws["!cols"] || [];
    ws["!cols"][columnIndex] = { hidden: false, wpx: width };
  });

  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][8] = { hpt: 30, hpx: 30 };
  ws["!rows"][8 + data?.products?.length + 1] = { hpt: 200, hpx: 200 };

  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(utils.decode_range("A1:F1"));
  ws["!merges"].push(utils.decode_range("A2:F2"));
  ws["!merges"].push(utils.decode_range("A3:F3"));
  ws["!merges"].push(utils.decode_range("A4:F4"));
  ws["!merges"].push(utils.decode_range("A5:F5"));
  ws["!merges"].push(utils.decode_range("A6:F6"));
  ws["!merges"].push(utils.decode_range("A7:F7"));
  ws["!merges"].push(utils.decode_range("A8:F8"));
  ws["!merges"].push(utils.decode_range("G1:K7"));
  ws["!merges"].push(utils.decode_range("G8:I8"));
  ws["!merges"].push(utils.decode_range("A9:D9"));
  ws["!merges"].push(utils.decode_range("E9:F9"));
  ws["!merges"].push(utils.decode_range("G9:H9"));
  ws["!merges"].push(utils.decode_range("J9:K9"));
  const startRowData = 10;
  data?.products.forEach((_, idx) => {
    ws["!merges"].push(utils.decode_range(`A${startRowData + idx}:B${startRowData + idx}`));
    ws["!merges"].push(utils.decode_range(`C${startRowData + idx}:D${startRowData + idx}`));
    ws["!merges"].push(utils.decode_range(`J${startRowData + idx}:K${startRowData + idx}`));
  });
  ws["!merges"].push(
    utils.decode_range(
      `A${startRowData + data?.products?.length}:B${startRowData + data?.products?.length}`
    )
  );
  ws["!merges"].push(
    utils.decode_range(
      `C${startRowData + data?.products?.length}:D${startRowData + data?.products?.length}`
    )
  );
  ws["!merges"].push(
    utils.decode_range(
      `J${startRowData + data?.products?.length}:K${startRowData + data?.products?.length}`
    )
  );
  ws["!merges"].push(
    utils.decode_range(
      `A${startRowData + data?.products?.length + 1}:B${startRowData + data?.products?.length + 1}`
    )
  );
  ws["!merges"].push(
    utils.decode_range(
      `C${startRowData + data?.products?.length + 1}:D${startRowData + data?.products?.length + 1}`
    )
  );
  ws["!merges"].push(
    utils.decode_range(
      `J${startRowData + data?.products?.length + 1}:K${startRowData + data?.products?.length + 1}`
    )
  );

  writeFile(wb, "packing-list-template.xlsx");
};

export const convertBase64 = (type, val) => {
  try {
    return type === "encode" ? btoa(val) : atob(val);
  } catch (e) {
    toast.error(e.message);
  }
};

export const objectToQueryParam = (object) => {
  const params = new URLSearchParams();

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      params.append(key, object[key]);
    }
  }

  return params.toString();
};

let count = 1;
export const handleExpiredToken = async (cb) => {
  try {
    if (count > 1) return;
    if (window.location.href.includes("auth")) return;
    count++;
    const response = await refreshToken({
      refresh_token: convertBase64("decode", Cookies.get("refresh_token")),
    });

    if (response?.data?.access_token) {
      count--;
      Cookies.set("access_token", convertBase64("encode", response?.data?.access_token));
      Cookies.set("refresh_token", convertBase64("encode", response?.data?.refresh_token));
      await cb();
      return;
    }
    if (window.location.href.includes("auth")) return;
    toast.error("Session expired, you will be redirected to login");
    setTimeout(() => {
      window.location.href = "/authentication/sign-in";
      sessionStorage.clear();
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
    }, 3000);
  } catch (error) {
    toast.error(error.message);
  }
};

export const previewImage = (file, callback) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    const previewURL = reader.result;
    callback(previewURL);
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

export const currencyFormat = (format, number, withDecimal = true) => {
  let result = 0;
  if (typeof number === "number") {
    if (withDecimal) {
      result = new Intl.NumberFormat(format, {
        ...DEFAULT_CURRENCY_OPTIONS,
        maximumFractionDigits: 0,
      }).format(number);
    } else {
      result = new Intl.NumberFormat(format, DEFAULT_CURRENCY_OPTIONS).format(number);
    }
  }

  return result;
};

export function generateSKU(categoryCode, productCode, length = 8) {
  // Memastikan panjang SKU tidak kurang dari 2
  if (length < 2) {
    throw new Error("SKU length should be at least 2");
  }

  // Mengambil sebagian dari categoryCode dan productCode
  const categoryPart = categoryCode.slice(0, 2).toUpperCase();
  const productPart = productCode.slice(0, length - 2).toUpperCase();

  // Membuat bagian acak untuk SKU
  const randomPart = Array.from({ length: length - categoryPart.length - productPart.length }, () =>
    Math.random().toString(36).charAt(2).toUpperCase()
  ).join("");

  // Menggabungkan semua bagian
  const sku = categoryPart + productPart + randomPart;

  return sku;
}

export function generateUniqueId() {
  const timestamp = new Date().getTime().toString(36);
  const randomPart = Math.random().toString(36).substr(2, 6);
  const uniqueId = timestamp + randomPart;
  return uniqueId.toUpperCase(); // Jika ingin ID berupa huruf besar
}

// Memfilter rute berdasarkan peran pengguna
export const filteredMenuAccess = (routes, userInfo) => {
  if (!routes || routes.length === 0) return [];

  const getMenuByRole = (role) => {
    return MENU_ACCESS[role] || [];
  };

  const allowedRoutes = getMenuByRole(userInfo?.role);

  // Fungsi untuk memfilter rute berdasarkan path
  const filterRoute = (route) => {
    if (route.type === "collapse" && route.collapse) {
      return {
        ...route,
        collapse: filterRoutes(route.collapse),
      };
    } else if (route.route) {
      return allowedRoutes.includes(route.route) ? route : null;
    }
    return null;
  };

  const filterRoutes = (routes) => {
    return routes.map(filterRoute).filter((route) => route !== null);
  };

  return filterRoutes(routes);
};
