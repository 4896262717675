import { IconButton } from "@mui/material";
import React from "react";
import { useGlobalStore } from "store";

const ThemedIconButton = ({ children, ...rest }) => {
  const { darkMode } = useGlobalStore();
  return (
    <IconButton {...rest} color={darkMode ? "white" : "inherit"}>
      {children}
    </IconButton>
  );
};

ThemedIconButton.propTypes;
export default ThemedIconButton;
