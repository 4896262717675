import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import { addPackage } from "services/api";
import { packageData, addPackagePayload } from "./formData";
import { Stack } from "@mui/material";
import CustomTab from "components/UI/CustomTab";
import TabPanel from "components/UI/CustomTab/TabPanel";
import { deletePackage } from "services/api";
import { updatePackage } from "services/api";
import MDTypography from "components/MDTypography";
import { getPackageByInvoiceId } from "services/api";
import ThemedIconButton from "components/UI/ThemedIcon";
import { Delete, Edit } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { useGlobalStore } from "store";
const notRenderField = ["id", "created_at", "updated_at", "created_at", "updated_by", "updated_by"];

const tabs = [{ label: "Add Package" }, { label: "Manage Package" }];

function ConfigurePackageModal() {
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (_, newVal) => setTabValue(newVal);
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "configurePackage";
  const queryClient = useQueryClient();
  const [pack, setPack] = useState(addPackagePayload);
  const { sidenavColor } = useGlobalStore();

  const [editedPack, setEditedPack] = useState({});

  const { mutate, isPending } = useMutation({
    mutationFn: addPackage,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["packages"] });
      toast.success("new package was added successfully");
      setPack(addPackagePayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const { mutate: deletePack, isPending: pendingDelete } = useMutation({
    mutationFn: deletePackage,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["packages", data?.commercial_invoice_id] });
      toast.success("package was deleted successfully");
      setPack(addPackagePayload);
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const { mutate: updatePack, isPending: pendingUpdate } = useMutation({
    mutationFn: updatePackage,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["packages", data?.commercial_invoice_id] });
      toast.success("package was updated successfully");
      setEditedPack({});
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const { data: packages } = useQuery({
    queryKey: ["packages", data?.commercial_invoice_id],
    queryFn: () => getPackageByInvoiceId(data?.commercial_invoice_id),
  });

  const handleSubmit = (e, type) => {
    e.preventDefault();

    const packData = type === "add" ? pack : editedPack;
    for (const [key, val] of Object.entries(packData)) {
      if (!val) {
        toast.error(`${key} is required`);
        return;
      }
    }

    if (type === "add") {
      mutate({
        ...packData,
        quantity: Number(packData.quantity),
        commercial_invoice_id: Number(data?.commercial_invoice_id),
      });
    } else {
      updatePack({
        id: packData?.id,
        payload: {
          ...packData,
          quantity: Number(packData.quantity),
          commercial_invoice_id: Number(data?.commercial_invoice_id),
        },
      });
    }
  };

  const handleChangeInput = (e, type) => {
    const { name, value } = e.target;
    if (type === "add") {
      setPack({ ...pack, [name]: value });
    } else {
      setEditedPack({ ...editedPack, [name]: value });
    }
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Add Package">
      <MDBox component="form" onSubmit={(e) => handleSubmit(e, "add")}>
        <CustomTab tabs={tabs} tabValue={tabValue} handleSetTabValue={handleSetTabValue} />
        <TabPanel value={tabValue} index={0}>
          <Stack direction="column" gap={2}>
            {Object.entries(pack).map(([key, value]) => (
              <MDInput
                key={key}
                value={value}
                variant="standard"
                type={packageData[key]?.type}
                label={packageData[key]?.label}
                fullWidth
                disabled={key === "commercial_invoice_id"}
                name={key}
                onChange={(e) => handleChangeInput(e, "add")}
              />
            ))}
          </Stack>
          <FlexBox justifyContent="end">
            <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
              submit
            </ThemedButton>
          </FlexBox>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <MDTypography fontSize={16} mb={1} fontWeight="bold">
            Package List
          </MDTypography>
          <Stack spacing={1} sx={{ maxHeight: 400, overflowY: "auto" }}>
            {packages?.response?.data?.map((pack, idx) => (
              <FlexBox key={pack?.id} justifyContent="space-between">
                <FlexBox justifyContent="flex-start" gap={1} alignItems="start">
                  <MDTypography fontSize={14}>{idx + 1}. </MDTypography>
                  <MDBox>
                    <MDTypography fontSize={14}>
                      {pack?.quantity} {pack?.name}
                    </MDTypography>
                    <MDTypography fontSize={12.5}>
                      {pack?.description || "Descriptions"}
                    </MDTypography>
                  </MDBox>
                </FlexBox>
                <Stack direction="row">
                  <ThemedIconButton onClick={() => setEditedPack(pack)}>
                    <Edit fontSize="small" />
                  </ThemedIconButton>
                  <ThemedIconButton>
                    <Delete fontSize="small" />
                  </ThemedIconButton>
                </Stack>
              </FlexBox>
            ))}
          </Stack>
          {editedPack?.id && (
            <Stack direction="column" gap={2} mt={2}>
              <MDTypography fontSize={16} fontWeight="bold">
                Edited Package
              </MDTypography>
              {Object.entries(editedPack).map(
                ([key, value]) =>
                  packageData[key] && (
                    <MDInput
                      key={key}
                      value={value}
                      variant="standard"
                      type={packageData[key]?.type}
                      label={packageData[key]?.label}
                      fullWidth
                      disabled={key === "commercial_invoice_id"}
                      name={key}
                      onChange={(e) => handleChangeInput(e, "edit")}
                    />
                  )
              )}
              <FlexBox justifyContent="end">
                <MDButton variant="text" onClick={() => setEditedPack({})}>
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color={sidenavColor}
                  onClick={(e) => handleSubmit(e, "edit")}
                >
                  Submit
                </MDButton>
              </FlexBox>
            </Stack>
          )}
        </TabPanel>
      </MDBox>
    </MDModal>
  );
}

ConfigurePackageModal.propTypes;

export default ConfigurePackageModal;
