import { CloudDownload, CloudUpload, FileOpen } from "@mui/icons-material";
import { Card, CircularProgress, Stack, styled } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FlexBox from "components/UI/FlexBox";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadCommercialInvoice } from "services/api";
import { useGlobalStore } from "store";
import { convertBase64 } from "utils";
import VisuallyHiddenInput from "./components/VisuallyHiddenInput";

const handleDownload = async () => {
  try {
    // Ganti URL endpoint dengan endpoint API Anda
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/commercial-invoices-file/csv/sample`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${convertBase64("decode", Cookies.get("access_token"))}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch file");
    }

    // Ambil blob dari response
    const blob = await response.blob();

    // Buat URL untuk blob
    const url = window.URL.createObjectURL(blob);

    // Buat elemen <a> untuk mengunduh file
    const a = document.createElement("a");
    a.href = url;
    a.download = "CI_SAMPLE.csv"; // Nama file yang akan diunduh
    document.body.appendChild(a);
    a.click();

    // Bersihkan
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Download error:", error);
  }
};

const UploadInvoice = () => {
  const [file, setFile] = useState(null);
  const { sidenavColor } = useGlobalStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: onUploadInvoice, isPending } = useMutation({
    mutationFn: uploadCommercialInvoice,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(res?.response?.response_message);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["commercialInvoices"] });
      toast.success("new commercial invoice uploaded successfully");
      setFile(null);

      navigate(
        res?.response?.data?.commercial_invoice_id
          ? `/logistics/import/commercial-invoices/${res?.response?.data?.commercial_invoice_id}`
          : "/logistics/import/commercial-invoices"
      );
    },
    onError: (err) => {
      toast.error(err?.response);
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if the file type is CSV
      if (selectedFile.type === "text/csv") {
        setFile(selectedFile);
      } else {
        toast.error("Invalid file type format");
      }
    }
  };

  const handleUploadInvoice = () => {
    const formData = new FormData();
    formData.append("file", file);
    onUploadInvoice(formData);
  };

  return (
    <Card>
      <Stack alignItems="center" spacing={2} my={10}>
        <FlexBox gap={2}>
          <MDButton
            component="label"
            role={undefined}
            variant="gradient"
            color={sidenavColor}
            startIcon={<FileOpen />}
            tabIndex={-1}
          >
            {file ? "Change" : "Choose"} file
            <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange} />
          </MDButton>
          <MDTypography fontSize={13}>- OR -</MDTypography>

          <MDButton
            onClick={handleDownload}
            variant="outlined"
            startIcon={<CloudDownload />}
            color={sidenavColor}
          >
            Download Template
          </MDButton>
        </FlexBox>
        {file && (
          <>
            <MDTypography fontSize={14}>File: {file.name}</MDTypography>
            <MDButton
              disabled={isPending}
              onClick={handleUploadInvoice}
              variant="gradient"
              color={sidenavColor}
              startIcon={
                isPending ? <CircularProgress color={sidenavColor} size={10} /> : <CloudUpload />
              }
            >
              Upload Invoice
            </MDButton>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default UploadInvoice;
