import { InputLabel, Switch } from "@mui/material";
import FlexBox from "components/UI/FlexBox";
import React from "react";

const MDSwitchWithLabel = ({ checked, label, containerProps, onChange }) => {
  return (
    <FlexBox justifyContent="end" alignItems="center" {...containerProps}>
      <InputLabel variant="h6">{label}</InputLabel>
      <Switch checked={checked} onChange={onChange} />
    </FlexBox>
  );
};

MDSwitchWithLabel.propTypes;
export default MDSwitchWithLabel;
