/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

import SignInIllustration from "layouts/authentication/sign-in/illustration";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Images
import { useGlobalStore } from "store";
import ProtectedRoute from "helpers/protected-routes";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ModalProvider } from "providers/ModalProvider";
import { filteredMenuAccess } from "utils";
import FallbackUI from "components/UI/FallBackUI";
import { ToastContainer } from "react-toastify";
import { menuAccess } from "utils";
import { MENU_ACCESS } from "constants";

export default function App() {
  const { darkMode, userLoggedIn } = useGlobalStore();
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    filteredMenuAccess(allRoutes, userLoggedIn?.authUser).map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route element={<ProtectedRoute user={userLoggedIn} />} key={route.key}>
            <Route exact path={route.route} element={route.component} />
          </Route>
        );
      }

      return null;
    });

  if (Boolean(!userLoggedIn?.authUser && sessionStorage.getItem("authUser"))) return <FallbackUI />;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToastContainer
        position="top-right"
        theme={darkMode ? "dark" : "light"}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <CssBaseline />
      <ModalProvider />
      <Routes>
        {userLoggedIn ? (
          <Route element={<DashboardLayout />}>
            {getRoutes(routes)}
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    MENU_ACCESS[userLoggedIn?.authUser?.role][0] ||
                    "/logistics/import/commercial-invoices"
                  }
                />
              }
            />
          </Route>
        ) : null}
        <Route path="/authentication/sign-in" element={<SignInIllustration />} />
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
