import AddCompanyModal from "examples/Modals/AddCompanyModal";
import AddPackageModal from "examples/Modals/AddPackageModal";
import AddPackingListModal from "examples/Modals/AddPackingListModal";
import AddProductModal from "examples/Modals/AddProductModal";
import AddUserModal from "examples/Modals/AddUserModal";
import ConfigurePackageModal from "examples/Modals/ConfigurePackageModal";
import UpdateCompanyModal from "examples/Modals/UpdateCompanyModal";
import UpdatePackingListModal from "examples/Modals/UpdatePackingListModal";
import UpdateProductModal from "examples/Modals/UpdateProductModal";
import UpdateStatusModal from "examples/Modals/UpdateStatusModal";
import UpdateUserModal from "examples/Modals/UpdateUserModal";

export const ModalProvider = () => {
  return (
    <>
      <AddUserModal />
      <UpdateUserModal />
      <AddCompanyModal />
      <AddPackageModal />
      <AddProductModal />
      <UpdateProductModal />
      <AddPackingListModal />
      <UpdateCompanyModal />
      <UpdatePackingListModal />
      <UpdateStatusModal />
      {/* <ConfigurePackageModal /> */}
    </>
  );
};
