import { useMutation, useQueryClient } from "@tanstack/react-query";
import MDBox from "components/MDBox";
import MDModal from "components/MDModal";
import FlexBox from "components/UI/FlexBox";
import ThemedButton from "components/UI/ThemedButton";
import { useModal } from "hooks/use-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Autocomplete, Stack } from "@mui/material";
import { updateCommercialInvoiceStatus } from "services/api";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function UpdateStatusModal() {
  const { onClose, type, data } = useModal();
  const isOpenModal = type === "updateStatus";
  const queryClient = useQueryClient();
  const [invoice, setInvoice] = useState({});
  const [invoiceStatus, setInvoiceStatus] = useState({
    status: { label: "Approve", value: 2 },
    note: "",
  });

  useEffect(() => {
    setInvoice(data);
  }, [data]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateCommercialInvoiceStatus,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["invoice", Number(invoice?.id)] });
      queryClient.invalidateQueries({ queryKey: ["invoiceStatus", Number(invoice?.id)] });
      toast.success("status was updated successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (invoiceStatus?.status?.value === 2 && invoice?.products?.length === 0)
      return toast.error("please add product first");
    mutate({
      id: invoice.id,
      payload: {
        ...invoiceStatus,
        status: invoiceStatus?.status?.value,
        commercial_invoice_id: invoice.id,
      },
    });
  };

  return (
    <MDModal open={isOpenModal} handleClose={onClose} title="Update Status">
      <MDBox component="form" onSubmit={handleSubmit}>
        <Stack direction="column" gap={2}>
          <Autocomplete
            value={invoiceStatus?.status}
            options={[
              { label: "Revise", value: 1 },
              { label: "Reject", value: -1 },
              { label: "Approve", value: 2 },
            ]}
            getOptionLabel={(option) => option.label}
            onChange={(e, newVal) => setInvoiceStatus({ ...invoiceStatus, status: newVal })}
            renderInput={(params) => (
              <MDInput {...params} InputLabelProps={{ shrink: true }} variant="standard" />
            )}
          />
          {invoiceStatus?.status?.value !== 2 && (
            <MDInput
              label="Note"
              variant="standard"
              value={invoiceStatus?.note}
              onChange={(e) => setInvoiceStatus({ ...invoiceStatus, note: e.target.value })}
            />
          )}
          {(invoiceStatus?.status?.value === 2 || invoiceStatus?.status?.value === -1) && (
            <MDTypography fontSize={13}>
              {`Note: Please note that once you ${
                invoiceStatus?.status?.value === 2 ? "approve" : "reject"
              } this invoice data, it cannot be updated.`}
            </MDTypography>
          )}
        </Stack>
        <FlexBox justifyContent="end">
          <ThemedButton disabled={isPending} variant="gradient" type="submit" sx={{ mt: 2 }}>
            submit
          </ThemedButton>
        </FlexBox>
      </MDBox>
    </MDModal>
  );
}

UpdateStatusModal.propTypes;

export default UpdateStatusModal;
