/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/ala-background.jpeg";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "services/api";
import { useNavigate } from "react-router-dom";
import { convertBase64 } from "utils";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useGlobalStore } from "store";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import ThemedButton from "components/UI/ThemedButton";

function Illustration() {
  const [loginState, setLoginState] = useState({ username: "", password: "" });
  const navigate = useNavigate();
  const { setUserLoggedIn, sidenavColor } = useGlobalStore();

  const handleInputChange = ({ target: { name, value } }) =>
    setLoginState((prev) => ({ ...prev, [name]: value }));

  const { isPending, mutate } = useMutation({
    mutationFn: signIn,
    onSuccess: (res) => {
      if (!res?.response?.success) {
        toast.error(`${res?.response?.response_message}, ${res?.response?.errors[0]}`);
        return;
      }

      Cookies.set("access_token", convertBase64("encode", res?.response?.data?.access_token));
      Cookies.set("refresh_token", convertBase64("encode", res?.response?.data?.refresh_token));
      const decodeUser = jwtDecode(res?.response?.data?.access_token);
      sessionStorage.setItem("authUser", JSON.stringify(decodeUser));
      setUserLoggedIn({ authUser: decodeUser });
      navigate("/logistics/users");
    },
    onError: (err) => {
      toast.error(err?.message || "something went wrong");
    },
  });

  const handleSignIn = (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(loginState)) {
      if (!value) {
        return toast.error(`${key} is required`);
      }
    }
    mutate(loginState);
  };

  return (
    <IllustrationLayout
      title="Sign In ALA Dashboard"
      description="Enter your username and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form" onSubmit={handleSignIn}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            name="username"
            label="Username"
            onChange={handleInputChange}
            fullWidth
            disabled={isPending}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            name="password"
            label="Password"
            onChange={handleInputChange}
            fullWidth
            disabled={isPending}
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <ThemedButton
            color={sidenavColor}
            variant="gradient"
            size="large"
            type="submit"
            fullWidth
            disabled={isPending}
          >
            {isPending ? <CircularProgress size={14} mr={10} color="inherit" /> : "sign in"}
          </ThemedButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
