/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useQuery } from "@tanstack/react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useMemo } from "react";

// Data
import { getPackingLists } from "services/api";
import PackingListActions from "../commercial-invoices/components/PackingListActions";

function PackingList() {
  const { data, isLoading } = useQuery({ queryKey: ["packingLists"], queryFn: getPackingLists });

  const dataTable = useMemo(() => {
    const table = {
      columns: [
        {
          Header: "packing list id",
          accessor: "id",
          width: "15%",
        },
        {
          Header: "packing list number",
          accessor: "packing_list_no",
        },
        {
          Header: "invoice number",
          accessor: "commercial_invoice_id",
          Cell: (data) => (
            <MDTypography fontSize={13} component="span" color="inherit">
              {data?.row?.original?.commercial_invoice?.invoice_no}
            </MDTypography>
          ),
        },
        {
          Header: "Actions",
          accessor: "actions",
          width: "10%",
          Cell: (data) => <PackingListActions rowData={data?.row?.original} />,
        },
      ],
      rows: [],
    };

    if (!data?.response?.data) return table;
    table.rows = data?.response?.data;
    return table;
  }, [data?.response?.data]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Packing List
              </MDTypography>
              <MDTypography variant="button" color="text">
                All Packing List Data
              </MDTypography>
            </MDBox>
            <DataTable table={dataTable} isLoading={isLoading} canSearch />
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </>
  );
}

export default PackingList;
